import { useState } from 'react';
import { IconCaretDown, IconCaretUp } from '@belong/icons';
import { Button, Checkbox, Text } from '@belong/ui';
import type { MaintenanceModel, MaintenanceRecurrenceModel } from 'api/models';
import clsx from 'clsx';
import MaintenanceTaskComponent from 'components/MaintenanceTaskComponent/MaintenanceTaskComponent';
import { EmptyTaskList } from 'maintenance/components/empty-task-list/empty-task-list';
import { RecurringTaskItem } from 'maintenance/components/recurring-task-item/recurring-task-item';
import { STRINGS } from 'maintenance/strings';
import type { ProServiceView } from 'maintenance/types';
import { getActiveTasks, getActiveRecurringTasks, getClosedTasks, getTurnedOffRecurringTasks } from 'maintenance/utils';

type ConditionalProps =
  | {
      type: 'recurring';
      tasks: MaintenanceRecurrenceModel[];
    }
  | {
      type: 'non-recurring';
      tasks: MaintenanceModel[];
    };

type TaskListProps = ConditionalProps & {
  showMaintenanceModal: (task: MaintenanceModel | MaintenanceRecurrenceModel) => () => void;
  view?: ProServiceView;
  isConsentPendingList?: boolean;
};

export function TaskList({
  showMaintenanceModal,
  tasks = [],
  type,
  view = 'homeOwner',
  isConsentPendingList,
}: TaskListProps) {
  const [showCompleted, setShowCompleted] = useState(false);
  const [isListExpanded, setIsListExpanded] = useState(false);

  const activeTasks =
    type === 'non-recurring'
      ? getActiveTasks(tasks as MaintenanceModel[])
      : getActiveRecurringTasks(tasks as MaintenanceRecurrenceModel[]);

  const closedTasks =
    type === 'non-recurring'
      ? getClosedTasks(tasks as MaintenanceModel[])
      : getTurnedOffRecurringTasks(tasks as MaintenanceRecurrenceModel[]);

  const tasksToShow = showCompleted ? [...activeTasks, ...closedTasks] : activeTasks;

  const visibleTasks = isListExpanded ? tasksToShow : tasksToShow.slice(0, 3);

  const handleShowCompletedChange = (newShowCompleted: boolean) => setShowCompleted(newShowCompleted);

  const handleShowMoreClick = () => setIsListExpanded((prevIsListExpanded) => !prevIsListExpanded);

  return (
    <>
      {!isConsentPendingList && (
        <div className="flex justify-between">
          <Text fontWeight="semibold">
            {type === 'non-recurring' ? STRINGS['non-recurring-title'] : STRINGS['recurring-title']}
          </Text>
        </div>
      )}
      {tasks.length ? (
        <>
          <div className={clsx('mt-sm flex flex-col', tasksToShow.length > 3 || closedTasks.length ? 'mb-sm' : 'mb-0')}>
            {type === 'non-recurring' ? (
              <MaintenanceTaskComponent
                showMaintenanceModal={showMaintenanceModal}
                tasks={visibleTasks as MaintenanceModel[]}
                isRenter={view === 'resident'}
                isConsentPendingList={isConsentPendingList}
              />
            ) : (
              <>
                {visibleTasks.map((visibleTask) => (
                  <div className="pb-xs last-of-type:pb-0" key={visibleTask.uniqueId}>
                    <RecurringTaskItem
                      onClick={showMaintenanceModal}
                      task={visibleTask as MaintenanceRecurrenceModel}
                      view={view}
                    />
                  </div>
                ))}
              </>
            )}
            {!activeTasks.length && !showCompleted && <EmptyTaskList type={type} />}
          </div>
          <div className="grid grid-cols-2">
            <div className="col-span-1">
              <div className={closedTasks.length ? 'contents' : 'hidden'}>
                <Checkbox
                  checked={showCompleted}
                  id={`${STRINGS['show-completed']}-${type}-${closedTasks[0]?.uniqueId}`}
                  label={type === 'non-recurring' ? STRINGS['show-completed'] : STRINGS['show-turned-off']}
                  onChange={handleShowCompletedChange}
                />
              </div>
            </div>
            <div className="flex col-span-1 justify-end">
              <div className={tasksToShow.length > 3 ? 'contents' : 'hidden'}>
                <Button onClick={handleShowMoreClick} variant="text">
                  <div className="flex">
                    {STRINGS.show} {isListExpanded ? STRINGS.less : STRINGS.more}
                    <div className="flex items-center ml-xs">
                      {isListExpanded ? (
                        <IconCaretUp height={15} width={15} />
                      ) : (
                        <IconCaretDown height={15} width={15} />
                      )}
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="mt-sm">
          <EmptyTaskList type={type} />
        </div>
      )}
    </>
  );
}
