import { startCase } from '@belong/common';
import { MaintenanceModel } from 'api/models';
import { RequestType } from 'models/enums/index';

export const getEstimatedCostFromCost = (estimatedcost, splitBy = 1) => {
  const x = parseInt(estimatedcost, 10) / splitBy;
  return Math.floor(x).toString();
};

const objectOfStatuses = {
  PendingAssessment: 'Reviewing',
  InProgress: 'In Progress',
  AssessmentScheduled: 'In Progress',
  PendingQuote: 'In Progress',
  PendingScheduling: 'In Progress',
  // OwnerRejected: 'Not Approved',
  // ResidentRejected: 'Cancelled',
  Canceled: 'Cancelled',
  Cancelled: 'Cancelled',
  RequireFollowup: 'Followup Required',
  AppointmentScheduled: 'Scheduled',
  Completed: 'Completed',
  Rejected: 'Not Approved',
  'Not Approved': 'Not Approved',
  Duplicate: 'Duplicate',
  'Not an Issue': 'Closed',
  'Will not do': 'Will Not Do',
};

export const formatMaintenanceStringsForTasks = (maintenance: MaintenanceModel) => {
  // In the payments return object we always show approval pending.
  // Is it ok to show approval pending until everyone confirms?

  if (maintenance.status !== 'Closed') {
    if (maintenance.workOrder?.status) {
      return startCase(maintenance.workOrder.status);
    }

    if (maintenance.requestCategory !== 'InHomeRepairs') {
      if (maintenance.nextAppointmentUniqueId) {
        return objectOfStatuses.AppointmentScheduled;
      }

      if (!maintenance.nextAppointmentUniqueId && !!maintenance.appointments.length) {
        return objectOfStatuses.RequireFollowup;
      }
    }
  }

  if (maintenance.result) {
    if (objectOfStatuses[maintenance.result]) {
      return objectOfStatuses[maintenance.result];
    }
  }

  return objectOfStatuses[maintenance.status] || maintenance.status;
};

export const getImage = (nameOfTask) => {
  switch (nameOfTask) {
    case RequestType.Repair:
    case RequestType.Group:
      return '/pro-services/repair.svg';
    case RequestType.Addition:
      return '/pro-services/addition.svg';
    case RequestType.Upgrade:
      return '/pro-services/upgrade.svg';
    case RequestType.Preventative:
      return '/pro-services/preventative.svg';
    case RequestType.Recondition:
      return '/pro-services/reconditioning.svg';
    case RequestType.PickupAndDelivery:
      return '/pro-services/pickup.svg';
    case RequestType.HaulingAndStorage:
      return '/pro-services/hauling.svg';
    case RequestType.Cleaning:
      return '/pro-services/cleaning.svg';
    case RequestType.Inspection:
      return '/pro-services/inspections.svg';
    case RequestType.KeysAndAccess:
      return '/pro-services/keys.svg';
    case RequestType.Photography:
      return '/pro-services/photography.svg';
    case RequestType.Investigation:
      return '/pro-services/investigation.svg';
    case 'Recurring':
    default:
      return '/pro-services/legacy.svg';
  }
};

export const getStringFromTime = (repeatDays) => {
  if (repeatDays === 365) {
    return 'Yearly';
  } else if (repeatDays === 90) {
    return 'Quarterly';
  } else if (repeatDays === 30) {
    return 'Monthly';
  } else if (repeatDays > 0) {
    return `Every ${repeatDays} Days`;
  }
};
