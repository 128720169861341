import { useMemo } from 'react';
import { MaintenanceRecurrenceDetailModel } from 'api/models';
import Grid from 'components/Grid/Grid';
import { MaintenanceCostDisplay } from 'containercomponents/MaintenanceStatus/MaintenanceCostDisplay/MaintenanceCostDisplay';
import { startCase } from 'lodash-es';
import { getFormattedFrequency } from 'maintenance/utils';
import MaintenanceTaskModel from 'models/maintenance/MaintenanceTaskModel';
import { MaintenanceRecurrenceAppointments } from 'pages/Accounts/Components/maintenance-recurrence/components/maintenance-recurrence-appointments';
import { MaintenanceRecurrenceFooter } from 'pages/Accounts/Components/maintenance-recurrence/components/maintenance-recurrence-footer';
import { MaintenanceRecurrenceHeader } from 'pages/Accounts/Components/maintenance-recurrence/components/maintenance-recurrence-header';
import { MaintenanceRecurrenceNavBar } from 'pages/Accounts/Components/maintenance-recurrence/components/maintenance-recurrence-nav-bar';
import { MaintenanceRecurrenceResidentNotice } from 'pages/Accounts/Components/maintenance-recurrence/components/maintenance-recurrence-resident-notice';
import {
  getFormattedAddress,
  getRecurrenceAuthorizationNeeded,
} from 'pages/Accounts/Components/maintenance-recurrence/maintenance-recurrence.utils';
import { MaintenanceRecurrenceNotes } from './components/maintenance-recurrence-notes';

type MaintenanceRecurrentSubtaskProps = {
  masterTask: MaintenanceRecurrenceDetailModel;
  subtask: MaintenanceTaskModel;
  onClosePress: () => void;
  isResident?: boolean;
};

export const MaintenanceRecurrentSubtask = ({
  masterTask,
  subtask,
  onClosePress,
  isResident = false,
}: MaintenanceRecurrentSubtaskProps) => {
  const isResidentAccessRequired = useMemo(
    () => getRecurrenceAuthorizationNeeded(masterTask?.maintenanceRecurrence?.recurringTask),
    [masterTask?.maintenanceRecurrence?.recurringTask]
  );

  const isPricingSectionVisible = !isResident || (isResident && subtask?.requestedBy === 'Resident');

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full">
        <MaintenanceRecurrenceNavBar onClosePress={onClosePress} />
      </div>
      <div className="sm:max-w-[730px] px-2sm sm:px-0">
        {!!masterTask && (
          <Grid extraPadding={false} className="p-0">
            <div className="mb-2xl">
              <MaintenanceRecurrenceHeader
                title={startCase(subtask?.summary || masterTask.maintenanceRecurrence.summary)}
                frequency={
                  masterTask?.maintenanceRecurrence?.repeatFrequencyDays &&
                  getFormattedFrequency(masterTask?.maintenanceRecurrence?.repeatFrequencyDays)
                }
                homeAddress={getFormattedAddress(subtask?.address || masterTask.home.address)}
              />
            </div>
            {isResident &&
              (masterTask?.maintenanceRecurrence.requestedBy === 'Homeowner' || isResidentAccessRequired) && (
                <div className="mb-2xl">
                  <MaintenanceRecurrenceResidentNotice
                    frequency={getFormattedFrequency(masterTask?.maintenanceRecurrence.repeatFrequencyDays)}
                    isAccessRequired={isResidentAccessRequired}
                    isRequestedByHomeowner={masterTask?.maintenanceRecurrence.requestedBy === 'Homeowner'}
                    conciergeName={masterTask?.concierge?.firstName}
                    homeownerName={masterTask.homeowner?.firstName}
                    isSubtask
                  />
                </div>
              )}
            <MaintenanceRecurrenceAppointments appointments={subtask?.appointments} disabled={!subtask} />
            {!!subtask && <MaintenanceRecurrenceNotes subtask={subtask} isResident={isResident} />}
            {isPricingSectionVisible && (
              <MaintenanceCostDisplay
                task={subtask?.actualCost || subtask?.estimatedCost}
                actualCost={!!subtask?.actualCost}
                fullTask={subtask || masterTask}
                isRenter={isResident}
                disabled={!subtask || (!subtask.actualCost && !subtask.estimatedCost)}
                customClassName="m-0 p-0"
                defaultClassName="block"
                hideBackgroundIcon
              />
            )}
            <MaintenanceRecurrenceFooter isResident={isResident} isSubtask task={masterTask} />
          </Grid>
        )}
      </div>
    </div>
  );
};
