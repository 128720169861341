import classNames from 'classnames/bind';
import styles from './GeneralIcon.module.css';
import { ICONS, COLORS } from './constants';

export { COLORS } from './constants';

type ColorKeys = keyof typeof COLORS;
type ColorValues = typeof COLORS[ColorKeys];
type IconKeys = keyof typeof ICONS;
type IconValues = typeof ICONS[IconKeys];

export type GeneralIconProps = {
  icon: IconValues;
  scale?: number;
  mobileScale?: number;
  color?: ColorValues;
  hoverColor?: ColorValues;
  hasEffects?: boolean;
  className?: string;
  iconClassName?: string;
  style?: Record<string, unknown>;
};

const cx = classNames.bind(styles);

export const GENERAL_ICONS = ICONS;

export function GeneralIcon({
  icon,
  className,
  scale = 1,
  mobileScale = null,
  color = COLORS.DEFAULT,
  hoverColor = COLORS.DEFAULT,
  hasEffects,
  style,
  iconClassName,
}: GeneralIconProps): JSX.Element {
  return (
    <span
      aria-hidden="true"
      className={cx('wrap-icon', `wrap-${icon}-scale-${scale.toString().replace('.', '-')}`, className)}
      style={style}
      data-testid="general-icon"
    >
      <span
        className={cx(
          'icon',
          {
            hasEffects,
            scalemobile: mobileScale ? `mobile-${mobileScale.toString().replace('.', '-')}` : undefined,
            [icon as string]: icon,
            [color]: color !== COLORS.DEFAULT,
            [`hover-${hoverColor}`]: hoverColor !== COLORS.DEFAULT,
          },
          iconClassName
        )}
      />
    </span>
  );
}

export default GeneralIcon;
