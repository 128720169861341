import { PATHS, kebabCase } from '@belong/common';
import { STEP_GATE_NAMES } from 'accounts/constants/steps';
import { SECTIONS_STRINGS } from 'accounts/constants/strings/sections.strings';
import type { FlowFlowModel, ReportModel, InspectionInspectionModel, UnitLeaseDetailsModel } from 'api/models';
import { isAfter } from 'date-fns';
import { groupBy } from 'lodash-es';
import { ReportStatus, SetupDataTypes, StepStatus, FlowStatus, ReportType, FlowType } from 'models/enums';
import {
  extractAndSortStepsInGroup,
  getStepByName,
  PostInspectionFlowGroupConfig,
} from 'pages/PostInspectionFlow/step.consts';
import { getStepPathFromConfig } from 'pages/PostInspectionFlow/steps/routes';
import { formatAddressWithCity } from 'utils/formatAddress';
import { isStepUnHidden } from './flow-steps';

function getPropertyOrUnitStepsMetadata({ stepsByGate, gateName, propertyId, unitId }) {
  const propertySteps = groupBy(stepsByGate[gateName], 'dataRelId')?.[propertyId] || [];
  const unitSteps = groupBy(stepsByGate[gateName], 'dataRelId')?.[unitId] || [];
  const steps = [...propertySteps, ...unitSteps];
  const noHiddenSteps = steps.filter(isStepUnHidden);
  const completedSteps = noHiddenSteps.filter((step) => step.status === StepStatus.Completed) || [];
  const progressPercentage = Math.round((completedSteps.length * 100) / noHiddenSteps.length || 0);

  return { steps: noHiddenSteps, completedSteps, progressPercentage };
}

function getReportStepsMetadata({ report, reportType, postInspectionSteps }) {
  const isUnpublished = report?.status === ReportStatus.Unpublished;
  const isCompleted = report?.status === ReportStatus.Completed;
  const isConfirmedOrCompleted = report?.isConfirmed || isCompleted;

  const { key } = PostInspectionFlowGroupConfig.find((groupConfig) => groupConfig.name === reportType);

  const reportSteps = extractAndSortStepsInGroup(postInspectionSteps, key);

  const noHiddenSteps = postInspectionSteps?.filter(isStepUnHidden) || [];
  const completedSteps = noHiddenSteps.filter((step) => step.status === StepStatus.Completed) || [];
  const progressPercentage = Math.round((completedSteps.length * 100) / noHiddenSteps.length || 0);

  const firstNonCompletedStep = noHiddenSteps.find((step) => step.status !== 'Completed') || reportSteps[0];

  // We replace the id since the paths are based on the uniqueId
  const link = reportSteps.length
    ? getStepPathFromConfig({ ...firstNonCompletedStep, id: firstNonCompletedStep.uniqueId })
    : null;

  return {
    isUnpublished,
    isConfirmedOrCompleted,
    progressPercentage,
    link,
  };
}

type GetLandlordInsuranceSectionParams = {
  flows: FlowFlowModel[];
  unitId: string;
};

function getUnitLandlordInsuranceSectionMetadata({ flows, unitId }: GetLandlordInsuranceSectionParams) {
  const unitLandlordInsuranceFlow = flows.filter((flow) =>
    flow.configuration?.unitIds?.some((id: string) => id === unitId)
  )?.[0];

  if (typeof unitLandlordInsuranceFlow === 'undefined') {
    return null;
  }

  const unitLandlordInsuranceSectionMetadata = {
    name: STEP_GATE_NAMES.LandlordInsurance,
    displayName: SECTIONS_STRINGS.add_insurance,
    reportLink: `${PATHS.LANDLORD_INSURANCE_FLOW}?flowId=${unitLandlordInsuranceFlow.uniqueId}`,
  };

  if (unitLandlordInsuranceFlow?.status === 'Completed') {
    return {
      ...unitLandlordInsuranceSectionMetadata,
      progressPercentage: 100,
      isCompleted: true,
      disabled: true,
    };
  }

  const allSteps = unitLandlordInsuranceFlow.steps.length;
  const completedSteps = unitLandlordInsuranceFlow.steps.filter((step) => step.status === 'Completed').length;
  const progressPercentage = Math.round((completedSteps / allSteps) * 100);

  return {
    ...unitLandlordInsuranceSectionMetadata,
    progressPercentage,
    isCompleted: false,
    disabled: false,
  };
}

function getPostInspectionFlowDisplayName(flowType: FlowFlowModel['flowType'], flowSubType: string) {
  if (flowType === ReportType.Improvements) {
    switch (flowSubType) {
      case 'Onboarding':
        return SECTIONS_STRINGS['home_improvements.displayName'];
      case 'MoveOut':
        return SECTIONS_STRINGS['home_improvements_pre_move_out.displayName'];
      case 'PreMoveOut':
        return SECTIONS_STRINGS['home_improvements_move_out.displayName'];
      default:
        return null;
    }
  }

  switch (flowSubType) {
    case 'Onboarding':
      return SECTIONS_STRINGS['home_pricing.displayName'];
    case 'MoveOut':
    case 'PreMoveOut':
      return SECTIONS_STRINGS['home_pricing_move_out.displayName'];
    default:
      return null;
  }
}

type ReportMetadataByTypeProps = {
  flow: FlowFlowModel;
  flowType: FlowFlowModel['flowType'];
  flowSubType: string;
  reports: ReportModel[];
  reportId: ReportModel['uniqueId'];
};

function getReportMetadataByType({ flow, flowType, flowSubType, reports, reportId }: ReportMetadataByTypeProps) {
  const sections = [];

  const {
    progressPercentage,
    isUnpublished: isReportUnpublished,
    isConfirmedOrCompleted: isReportCompletedOrConfirmed,
    link: reportLink,
  } = getReportStepsMetadata({
    report: reports.find((report) => report?.uniqueId === reportId),
    reportType: flowType,
    postInspectionSteps:
      flow?.steps.map((step) => ({
        ...step,
        ...getStepByName(step.stepName),
      })) || [],
  });

  sections.push({
    name: flowType === ReportType.Improvements ? STEP_GATE_NAMES.HomeImprovements : STEP_GATE_NAMES.HomePricing,
    displayName: getPostInspectionFlowDisplayName(flowType, flowSubType),
    progressPercentage,
    isCompleted: flowType === ReportType.Improvements ? flow?.status === 'Completed' : isReportCompletedOrConfirmed,
    disabled:
      isReportUnpublished ||
      (flowType === ReportType.Pricing && isReportCompletedOrConfirmed) || // Should only disable when report confirmed on improvements
      !reportLink || 
      progressPercentage >= 100,
    reportLink,
  });

  return sections;
}

type ReportSectionMedatadaProps = {
  flows: FlowFlowModel[];
  unitId: string;
  inspections: InspectionInspectionModel[];
  reports: ReportModel[];
};

function getReportSectionMetadata({ flows, unitId, inspections, reports }: ReportSectionMedatadaProps) {
  const unitFlows = flows.filter((flow) => flow.configuration?.homeUniqueId === unitId);
  const sections = [];

  // Find latest inspection
  let latestInspection = null;

  latestInspection = inspections?.find((inspection) => inspection.status === 'Pending');

  if (!latestInspection) {
    latestInspection = inspections?.reduce((prev, current) => {
      if (!prev) {
        return current;
      }

      return isAfter(new Date(current.scheduledOn), new Date(prev.scheduledOn)) ? current : prev;
    }, null);
  }

  // Find latest pricing flow based on latest inspection
  const pricingFlow = unitFlows.reduce((prev, current) => {
    if (
      current.flowType === ReportType.Pricing &&
      current.configuration.flowSubType === latestInspection?.type &&
      isAfter(new Date(current.auditInfo.createdOn), new Date(latestInspection?.createdOn || null))
    ) {
      if (!prev) {
        return current;
      }

      return isAfter(new Date(current.auditInfo.createdOn), new Date(prev.auditInfo.createdOn)) ? current : prev;
    }

    return prev;
  }, null);

  const pricingReportId = pricingFlow?.steps.find((step) => step.dataType === 'Report')?.dataUniqueId;

  sections.push(
    ...getReportMetadataByType({
      flow: pricingFlow,
      flowType: 'Pricing',
      flowSubType: latestInspection?.type || 'Onboarding',
      reports,
      reportId: pricingReportId,
    })
  );

  // Find latest improvement flow based on latest inspection
  const improvementFlow = unitFlows.reduce((prev, current) => {
    if (
      current.flowType === ReportType.Improvements &&
      current.configuration.flowSubType === latestInspection?.type &&
      isAfter(new Date(current.auditInfo.createdOn), new Date(latestInspection?.createdOn || null))
    ) {
      if (!prev) {
        return current;
      }

      return isAfter(new Date(current.auditInfo.createdOn), new Date(prev.auditInfo.createdOn)) ? current : prev;
    }

    return prev;
  }, null);

  const improvementReportId = improvementFlow?.steps.find((step) => step.dataType === 'Report')?.dataUniqueId;

  sections.push(
    ...getReportMetadataByType({
      flow: improvementFlow,
      flowType: 'Improvements',
      flowSubType: latestInspection?.type || 'Onboarding',
      reports,
      reportId: improvementReportId,
    })
  );

  return sections;
}

function getPreMoveOutSectionMetadata({ flows, leaseId }) {
  const preMoveOutFlow = flows
    .filter((flow) => flow.flowType === 'HomeownerPreMoveOut' && flow.configuration?.leaseUniqueId === leaseId)
    .sort((a, b) => (isAfter(new Date(a.auditInfo.createdOn), new Date(b.auditInfo.createdOn)) ? -1 : 1))[0];

  if (preMoveOutFlow) {
    const { uniqueId: flowId, steps } = preMoveOutFlow;
    const firstNonCompletedStep = steps?.filter((step) => !step.isHidden).find((step) => step.status !== 'Completed');
    const { uniqueId: stepId, stepName } = firstNonCompletedStep || {};

    const noHiddenSteps = steps.filter(isStepUnHidden);
    const completedSteps = noHiddenSteps.filter((step) => step.status === StepStatus.Completed) || [];
    const progressPercentage = Math.round((completedSteps.length * 100) / noHiddenSteps.length || 0);

    return {
      name: STEP_GATE_NAMES.PreMoveOut,
      displayName: SECTIONS_STRINGS['pre_move_out.displayName'],
      progressPercentage,
      isCompleted: progressPercentage === 100,
      disabled: progressPercentage === 100,
      reportLink: `${PATHS.HO_PRE_MOVE_OUT_FLOW}?flowId=${flowId}&stepId=${stepId}&stepName=${kebabCase(stepName)}`,
    };
  }

  return null;
}

export function getPropertySection({ stepsByGate, selectedProperty, reports, flows, inspections, units }) {
  /**
   * Now we can send agreements for homes that are part of a multi-home onboarding flow without waiting
   * for every single one of them to be agreement-ready.
   * The "units" param only considers homes that have an active agreement.
   * Therefore, we are filtering property units based on the units that are part of an agreement.
   */
  const unitIds = (units as UnitLeaseDetailsModel[])?.map((unit) => unit.basicInfo.unitId) ?? [];

  const propertySection = selectedProperty?.units
    .map((unit) => {
      const unitSubSections = [];
      const isAdoptedResident = unit.isAdoptedAgreement;

      if (!unitIds.includes(unit.basicInfo.unitId)) {
        return;
      }

      const inspectionScheduleContentProps = {
        subtitle: SECTIONS_STRINGS['what_you_need.sub_title'],
        items: isAdoptedResident
          ? [{ text: SECTIONS_STRINGS.current_lease }, { text: SECTIONS_STRINGS.resident_contact_info }]
          : [],
      };

      const buildListingContentProps = {
        title: selectedProperty?.basicInfo.hasHoa ? SECTIONS_STRINGS.hoa_agreement_and_info : null,
        items: [],
      };

      const { progressPercentage: inspectionSchedulePercentage, steps: inspectionScheduleSteps } =
        getPropertyOrUnitStepsMetadata({
          stepsByGate,
          gateName: STEP_GATE_NAMES.InspectionSchedule,
          propertyId: selectedProperty?.basicInfo.propertyId,
          unitId: unit?.basicInfo.unitId,
        });

      const { progressPercentage: buildListingPercentage, steps: buildListingSteps } = getPropertyOrUnitStepsMetadata({
        stepsByGate,
        gateName: STEP_GATE_NAMES.BuildListing,
        propertyId: selectedProperty?.basicInfo.propertyId,
        unitId: unit?.basicInfo.unitId,
      });

      unitSubSections.push({
        name: STEP_GATE_NAMES.InspectionSchedule,
        displayName: isAdoptedResident
          ? SECTIONS_STRINGS['inspection_schedule.adopted_resident.displayName']
          : SECTIONS_STRINGS['inspection_schedule.displayName'],
        progressPercentage: inspectionSchedulePercentage,
        isCompleted: inspectionSchedulePercentage === 100,
        steps: inspectionScheduleSteps,
        additionalContentProps: inspectionScheduleContentProps.items?.length ? inspectionScheduleContentProps : null,
        disabled: inspectionSchedulePercentage === 100,
      });

      unitSubSections.push({
        name: STEP_GATE_NAMES.BuildListing,
        displayName: isAdoptedResident
          ? SECTIONS_STRINGS['build_listing.adopted_resident.displayName']
          : SECTIONS_STRINGS['build_listing.displayName'],
        progressPercentage: buildListingPercentage,
        isCompleted: buildListingPercentage === 100,
        steps: buildListingSteps,
        additionalContentProps:
          (buildListingContentProps.items?.length || buildListingContentProps.title) && buildListingPercentage !== 100
            ? buildListingContentProps
            : null,
        disabled: buildListingSteps?.every((step) =>
          [FlowStatus.Cancelled, FlowStatus.Completed].includes(step.status)
        ),
      });

      const unitlandlordInsuranceFlowMetadata = getUnitLandlordInsuranceSectionMetadata({
        flows,
        unitId: unit.basicInfo.unitId,
      });

      if (unitlandlordInsuranceFlowMetadata) {
        unitSubSections.push(unitlandlordInsuranceFlowMetadata);
      }

      const preMoveOutFlowMetadata = getPreMoveOutSectionMetadata({
        flows,
        leaseId: unit.currentLease?.basicInfo.leaseId,
      });

      if (preMoveOutFlowMetadata) unitSubSections.push(preMoveOutFlowMetadata);

      const unitInspections = inspections?.filter((inspection) => inspection.homeUniqueId === unit.basicInfo.unitId);

      if (!isAdoptedResident || (isAdoptedResident && unitInspections?.length > 0)) {
        const unitReports = reports
          .filter((reportMetadata) => reportMetadata.report.homeId === unit?.basicInfo.unitId)
          ?.map((reportMetadata) => reportMetadata.report);

        const reportsMetadata = getReportSectionMetadata({
          flows,
          inspections: unitInspections,
          unitId: unit.basicInfo.unitId,
          reports: unitReports,
        });

        unitSubSections.push(...reportsMetadata);
      }

      return {
        sectionTitle: formatAddressWithCity(selectedProperty?.address, unit.basicInfo.unitNumber),
        subSections: unitSubSections,
        unitId: unit.basicInfo.unitId,
        timeZone: unit.basicInfo.timeZone,
        isCompleted: unitSubSections.every((subSection) => subSection.isCompleted),
      };
    })
    .filter(Boolean);

  return propertySection;
}

function getAccountStepsMetadata({ stepsByGate, gateName, property }) {
  const steps = stepsByGate[gateName]?.filter((step) =>
    step.dataType === SetupDataTypes.Home
      ? property?.units.some((unit) => unit.basicInfo.unitId === step.dataUniqueId)
      : true
  );
  const noHiddenSteps = steps.filter(isStepUnHidden);
  const completedSteps = noHiddenSteps.filter((step) => step.status === StepStatus.Completed);
  const progressPercentage = Math.round((completedSteps.length * 100) / noHiddenSteps.length);

  return { steps: noHiddenSteps, completedSteps, progressPercentage };
}

export function getUserSection({ stepsByGate, property, flows }) {
  const subSections = [];

  if (stepsByGate[STEP_GATE_NAMES.NextLevelProfile]?.length) {
    const { progressPercentage: nextLevelProfilePercentage, steps: nextLevelProfileSteps } = getAccountStepsMetadata({
      stepsByGate,
      gateName: STEP_GATE_NAMES.NextLevelProfile,
      property,
    });

    subSections.push({
      name: STEP_GATE_NAMES.NextLevelProfile,
      displayName: SECTIONS_STRINGS['next_level_profile.displayName'],
      progressPercentage: nextLevelProfilePercentage,
      isCompleted: nextLevelProfilePercentage === 100,
      steps: nextLevelProfileSteps,
      disabled: nextLevelProfileSteps?.every((step) =>
        [FlowStatus.Cancelled, FlowStatus.Completed].includes(step.flowStatus)
      ),
    });
  }

  if (stepsByGate[STEP_GATE_NAMES.GetPaid]?.length) {
    const { progressPercentage: getPaidPercentage, steps: getPaidSteps } = getAccountStepsMetadata({
      stepsByGate,
      gateName: STEP_GATE_NAMES.GetPaid,
      property,
    });

    subSections.push({
      name: STEP_GATE_NAMES.GetPaid,
      displayName: SECTIONS_STRINGS['get_paid.displayName'],
      progressPercentage: getPaidPercentage,
      isCompleted: getPaidPercentage === 100,
      steps: getPaidSteps,
      disabled: getPaidSteps?.every((step) => [FlowStatus.Cancelled, FlowStatus.Completed].includes(step.flowStatus)),
      additionalContentProps: {
        subtitle: SECTIONS_STRINGS['what_you_need.sub_title'],
        items: [
          { text: SECTIONS_STRINGS.tax_info },
          { text: SECTIONS_STRINGS.insurance },
          { text: SECTIONS_STRINGS.valid_id },
        ],
      },
    });
  }

  const w9Flow = flows?.find((flow) => flow.flowType === FlowType.W9);
  if (w9Flow) {
    const isW9Completed = w9Flow?.status === 'Completed';
    subSections.push({
      name: STEP_GATE_NAMES.W9,
      displayName: SECTIONS_STRINGS.w9,
      reportLink: PATHS.W9_FLOW.replace(':flowId', w9Flow.uniqueId),
      progressPercentage: isW9Completed ? 100 : 0,
      isCompleted: isW9Completed,
      disabled: isW9Completed,
    });
  }

  const userSection = {
    sectionTitle: SECTIONS_STRINGS['account.title'],
    subSections,
    isCompleted: subSections.every((subSection) => subSection.isCompleted),
  };

  return userSection;
}
