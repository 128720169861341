import { find, includes } from 'lodash-es';

const getAddressComponentByType = (geoCodeAddressComponents, type) =>
  find(geoCodeAddressComponents, (addressComponent) => includes(addressComponent.types, type));

/*
 * Parse Google's geocoding response address components into address object.
 * Sample geo code https://developers.google.com/maps/documentation/geocoding/start#sample-request
 */

export const parseGeocodeAddress = (geocodeAddress, addressComponentObj) => {
  const geoCodeAddressComponents = geocodeAddress.address_components;
  const geoCodeLocation = geocodeAddress.geometry.location;
  const streetNumberComponent = getAddressComponentByType(geoCodeAddressComponents, 'street_number');
  const streetNumber = streetNumberComponent ? streetNumberComponent.long_name : '';

  let streetAddressComponent = getAddressComponentByType(geoCodeAddressComponents, 'route');
  let streetAddress = streetAddressComponent ? streetAddressComponent.long_name : '';

  if (!streetAddress) {
    streetAddressComponent = getAddressComponentByType(geoCodeAddressComponents, 'intersection');
    streetAddress = streetAddressComponent ? streetAddressComponent.long_name : '';
  }

  const postalCodeComponent = getAddressComponentByType(geoCodeAddressComponents, 'postal_code');
  const postalCode = postalCodeComponent ? postalCodeComponent.long_name : '';

  const localityComponent = getAddressComponentByType(geoCodeAddressComponents, 'locality');
  let locality = localityComponent ? localityComponent.long_name : '';

  if (!locality) {
    const politicalComponent = getAddressComponentByType(geoCodeAddressComponents, 'political');
    locality = politicalComponent ? politicalComponent.long_name : '';
  }

  const regionComponent = getAddressComponentByType(geoCodeAddressComponents, 'administrative_area_level_1');
  const region = regionComponent ? regionComponent.short_name : '';

  const countryComponent = getAddressComponentByType(geoCodeAddressComponents, 'country');
  const country = countryComponent ? countryComponent.short_name : '';

  const customAddressComponentObj = {};

  if (addressComponentObj) {
    customAddressComponentObj.zipcode = postalCode;
  }
  return {
    externalId: geocodeAddress.place_id,
    externalProviderData: JSON.stringify(geocodeAddress),
    streetAddress: streetNumber ? `${streetNumber} ${streetAddress}` : streetAddress,
    city: locality,
    state: region,
    zipcode: postalCode,
    country,
    latitude: geoCodeLocation.lat(),
    longitude: geoCodeLocation.lng(),
    ...customAddressComponentObj,
  };
};

export const parseInternationalGeocodeAddress = (geocodeAddress) => {
  const geoCodeAddressComponents = geocodeAddress.address_components;
  const geoCodeLocation = geocodeAddress.geometry.location;
  const countryComponent = getAddressComponentByType(geoCodeAddressComponents, 'country');
  const country = countryComponent ? countryComponent.short_name : '';
  const completeAddress = geocodeAddress.formatted_address;

  return {
    externalId: geocodeAddress.place_id,
    externalProviderData: JSON.stringify(geocodeAddress),
    country,
    completeAddress,
    latitude: geoCodeLocation.lat(),
    longitude: geoCodeLocation.lng(),
  };
};
