import React from 'react';
import type { ComponentProps } from 'react';
import { Button, Image, Modal } from '@belong/ui';

type Props = {
  isVisible?: ComponentProps<typeof Modal>['isOpen'];
  onDismiss: ComponentProps<typeof Modal>['onDismiss'];
  handleSuccessCallback: () => void;
  isLoading?: boolean;
};

export const GuarantedTimeToListModal: React.FC<Props> = ({
  isVisible = false,
  onDismiss,
  handleSuccessCallback,
  isLoading = false,
}) => (
  <Modal isOpen={isVisible} onDismiss={onDismiss} name="gttl-modal" overlayClassName="z-fullscreen">
    <div className="p-sm md:p-2xl text-center flex flex-col items-center">
      <div className="px-2xl pt-xl pb-2xl">
        <Image src="/post_inspection/gttl/home_image.svg" alt="" role="presentation" />
      </div>
      <h2 className="my-xs">Rent Faster, Save More.</h2>
      <p className="mb-xl md:mb-lg">
        Belong completes work faster, saving our homeowners an average of $1,000 in vacancy costs. Our network of
        skilled professionals and project managers ensures top efficiency without compromising quality.
      </p>
      <Button size="large" className="mb-lg md:mb-md" onClick={handleSuccessCallback} disabled={isLoading}>
        Let Belong handle it
      </Button>
      <Button variant="text" onClick={onDismiss}>
        I still prefer to do my own work.
      </Button>
    </div>
  </Modal>
);
