import { PATHS } from '@belong/common';
import { HomeownerSetupFlowSteps } from 'accounts/pages/homeowners/setup-flow/steps/steps';
import { ANALYTICS_PAGE_NAMES } from 'analytics';
import LeaseSigningFlow from 'lease-signing-flow/pages/flow/flow';
import { AddTask } from 'maintenance/components/add-task/add-task';
import { CreateProService } from 'maintenance/pages/create-pro-service/create-pro-service';
import { PageRedirectToBelongNext } from 'page-redirect-to-belong-next/PageRedirectToBelongNext';
import Accounts from 'pages/Accounts/Accounts';
import ResidentMaintenanceDetails from 'pages/Accounts/Pages/YourHome/Maintenance/MaintenanceDetails/resident-maintenance-details';
import ResidentMaintenanceRecurrent from 'pages/Accounts/Pages/YourHome/Maintenance/Recurrence/resident-maintenance-recurrence';
import ResidentMaintenanceRecurrentSubtask from 'pages/Accounts/Pages/YourHome/Maintenance/Recurrence/resident-maintenance-recurrence-subtask';
import HomeownerMaintenanceDetails from 'pages/Accounts/Pages/YourPortfolio/Maintenance/MaintenanceDetails/homeowner-maintenance-details';
import HomeownerMaintenanceRecurrent from 'pages/Accounts/Pages/YourPortfolio/Maintenance/Recurrence/homeowner-maintenance-recurrence';
import HomeownerMaintenanceRecurrentSubtask from 'pages/Accounts/Pages/YourPortfolio/Maintenance/Recurrence/homeowner-maintenance-recurrence-subtask';
import AgreementFlow from 'pages/AgreementFlow/AgreementFlow';
import { FileDownload } from 'pages/FileDownload/FileDownload';
import HomeOwnerOnboarding from 'pages/HomeOwnerOnboarding/HomeOwnerOnboarding';
import { OccupancyStandalone } from 'pages/OccupancyStandalone/OccupancyStandalone';
import Page404 from 'pages/Page404/Page404';
import PostInspectionFlow from 'pages/PostInspectionFlow/PostInspectionFlow';
import ResidentApplication from 'pages/ResidentApplication/ResidentApplication';
import ResidentCoApplicantLandingPage from 'pages/ResidentCoApplicantLandingPage/ResidentCoApplicantLandingPage';
import ResidentOnboarding from 'pages/ResidentOnboarding/ResidentOnboarding';

const getRoutesConfig = () => {
  return [
    {
      path: PATHS.SETUP_FLOW,
      component: HomeownerSetupFlowSteps,
      state: { page: ANALYTICS_PAGE_NAMES.HOMEOWNERS_SETUP_FLOW_STEPS },
    },
    {
      path: PATHS.POST_INSPECTION_FLOW,
      exact: true,
      component: PostInspectionFlow,
      state: { page: ANALYTICS_PAGE_NAMES.POST_INSPECTION_FLOW },
    },
    {
      path: PATHS.LANDLORD_INSURANCE_FLOW,
      exact: true,
      component: PageRedirectToBelongNext,
    },
    {
      path: PATHS.HO_BETTER_RENEWALS_FLOW,
      exact: true,
      component: PageRedirectToBelongNext,
    },
    {
      path: PATHS.RES_BETTER_RENEWALS_FLOW,
      exact: true,
      component: PageRedirectToBelongNext,
    },
    {
      path: PATHS.HO_PRE_MOVE_OUT_FLOW,
      exact: true,
      component: PageRedirectToBelongNext,
    },
    {
      path: PATHS.RESIDENTS_POST_MOVE_OUT_FLOW,
      exact: true,
      component: PageRedirectToBelongNext,
    },
    {
      path: PATHS.BELONG_X,
      exact: true,
      component: PageRedirectToBelongNext,
    },
    {
      path: PATHS.BELONG_X_RENTERS,
      exact: true,
      component: PageRedirectToBelongNext,
    },
    {
      path: PATHS.AGREEMENT_FLOW,
      exact: true,
      component: AgreementFlow,
      state: { page: ANALYTICS_PAGE_NAMES.AGREEMENT_FLOW },
    },
    { path: PATHS.SITEMAP, exact: true, component: PageRedirectToBelongNext },
    { path: PATHS.HOME_PAGE, exact: true, component: PageRedirectToBelongNext },
    { path: PATHS.LISTING_PAGE, exact: true, component: PageRedirectToBelongNext },
    { path: PATHS.W9_FLOW, exact: true, component: PageRedirectToBelongNext },
    { path: PATHS.HOUSE_PAGE, exact: true, component: PageRedirectToBelongNext },
    { path: PATHS.HOUSE_PAGE_HOME_UNIQUE_ID, exact: true, component: PageRedirectToBelongNext },
    { path: PATHS.HOUSE_PREVIEW_PAGE, exact: true, component: PageRedirectToBelongNext },
    { path: PATHS.OCCUPANCY_STANDALONE, component: OccupancyStandalone },
    {
      path: PATHS.HOMEOWNER_ONBOARDING,
      component: HomeOwnerOnboarding,
      state: { page: ANALYTICS_PAGE_NAMES.HOMEOWNERS_SIGNUP_FLOW },
    },
    {
      path: PATHS.PORTFOLIO_MAINTENANCE_RECURRENCE_SUBTASK,
      component: HomeownerMaintenanceRecurrentSubtask,
      exact: true,
    },
    {
      path: PATHS.PORTFOLIO_MAINTENANCE_RECURRENCE,
      component: HomeownerMaintenanceRecurrent,
      exact: true,
    },
    {
      path: PATHS.PORTFOLIO_MAINTENANCE,
      component: HomeownerMaintenanceDetails,
      exact: true,
    },
    {
      path: PATHS.HOME_MAINTENANCE_RECURRENCE_SUBTASK,
      component: ResidentMaintenanceRecurrentSubtask,
      exact: true,
    },
    {
      path: PATHS.HOME_MAINTENANCE_RECURRENCE,
      component: ResidentMaintenanceRecurrent,
      exact: true,
    },
    {
      path: PATHS.HOMEOWNER_CREATE_TASK,
      component: AddTask,
      exact: true,
    },
    {
      path: PATHS.HOMEOWNER_PRO_SERVICE_EDIT,
      component: CreateProService,
      exact: true,
    },
    {
      path: PATHS.HOMEOWNER_PRO_SERVICE_CREATE,
      component: CreateProService,
      exact: true,
    },
    {
      path: PATHS.HOME_MAINTENANCE,
      component: ResidentMaintenanceDetails,
      exact: true,
    },
    { path: PATHS.ACCOUNTS, component: Accounts, state: { page: ANALYTICS_PAGE_NAMES.ACCOUNT } },
    { path: PATHS.NEW_RESIDENT_APPLICATION_LEASE_STEP, exact: true, component: PageRedirectToBelongNext },
    { path: PATHS.NEW_RESIDENT_APPLICATION_PRE_APPLICANT_FLOW, exact: true, component: PageRedirectToBelongNext },
    { path: PATHS.NEW_RESIDENT_APPLICATION_CO_SIGNER_STEP, exact: true, component: PageRedirectToBelongNext },
    { path: PATHS.RESIDENT_APPLICATION, component: ResidentApplication },
    { path: PATHS.RESIDENT_ONBOARDING, component: ResidentOnboarding },
    {
      path: PATHS.RESIDENT_CREATE_TASK,
      component: AddTask,
      exact: true,
    },
    {
      path: PATHS.RESIDENT_PRO_SERVICE_EDIT,
      component: CreateProService,
      exact: true,
    },
    {
      path: PATHS.RESIDENT_PRO_SERVICE_CREATE,
      component: CreateProService,
      exact: true,
    },
    {
      path: PATHS.RESIDENT_LANDING,
      component: PageRedirectToBelongNext,
      state: { page: ANALYTICS_PAGE_NAMES.RESIDENTS_LANDING_PAGE },
    },
    { path: PATHS.COAPPLICANT, component: ResidentCoApplicantLandingPage },
    { path: PATHS.RESIDENT_LANDING, component: PageRedirectToBelongNext },
    { path: PATHS.ABOUT_US, component: PageRedirectToBelongNext },
    { path: PATHS.FAQ, component: PageRedirectToBelongNext },
    { path: PATHS.HOMEOWNER_LANDING, exact: true, component: PageRedirectToBelongNext },
    { path: PATHS.HOMEOWNER_CITY_LANDING, component: PageRedirectToBelongNext },
    { path: PATHS.HOMEOWNER_COMPARE, component: PageRedirectToBelongNext },
    { path: PATHS.TERMS, component: PageRedirectToBelongNext },
    { path: PATHS.PRIVACY_POLICY, component: PageRedirectToBelongNext },
    { path: PATHS.CAREERS, exact: true, component: PageRedirectToBelongNext },
    { path: PATHS.BLOG, exact: true, component: PageRedirectToBelongNext },
    { path: PATHS.REFERRAL_LANDING, exact: true, component: PageRedirectToBelongNext },
    { path: PATHS.HELP, exact: true, component: PageRedirectToBelongNext },
    { path: PATHS.SUPPORT, exact: true, component: PageRedirectToBelongNext },
    {
      path: PATHS.LEASE_SIGNING_FLOW,
      component: LeaseSigningFlow,
      state: { page: ANALYTICS_PAGE_NAMES.PRO_SERVICES },
    },
    { path: PATHS.FILE_DOWNLOAD, component: FileDownload, state: { page: ANALYTICS_PAGE_NAMES.FILE_DOWNLOAD } },
    { component: Page404, state: { page: ANALYTICS_PAGE_NAMES.PAGE_NOT_FOUND } },
  ];
};

export default getRoutesConfig;
