import { useHistory } from 'react-router';
import { formatDateTime, DATE_FORMATS, BASE_PATHS } from '@belong/common';
import { Text, Image } from '@belong/ui';
import CircledNumber from 'components/CircledNumber/CircledNumber';
import DividerRow from 'components/DividerRow/DividerRow';
import MultipleBelongEmployeeProfileView from 'components/MultipleBelongEmployeeProfileView/MultipleBelongEmployeeProfileView';
import Carousel from 'components/v2/Carousel/Carousel';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import { isYesterday } from 'date-fns';
import { legacyParse } from 'forkedlibraries/date-fns-upgrade';
import Page from 'layouts/Page/Page';
import { EmployeeAssignmentTypes } from 'models/enums';
import { getInspectionType } from 'pages/PostInspectionFlow/utils';
import { QUERY_PARAMS } from 'pages/PostInspectionFlow/utils';
import PropTypes from 'prop-types';
import { formatString } from 'strings';
import { EMPLOYEE_TITLES } from 'strings/common.strings';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import PostInspectionContentLayout from '../../../PostInspectionContentLayout/PostInspectionContentLayout';
import styles from './Intro.module.css';

const propTypes = {
  stepResource: PropTypes.object.isRequired,
  onSaveAndNext: PropTypes.func.isRequired,
  stepConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepConfig: PropTypes.object.isRequired,
  flow: PropTypes.object.isRequired,
};

const dividerRowItems = [
  {
    top: <CircledNumber>1</CircledNumber>,
    icon: <Image className={styles['divider-image']} src="/post_inspection/intro-1.png" />,
    subtitle: formatString(POST_INSPECTION_FLOW_STRINGS['intro.divider'][0]),
  },
  {
    top: <CircledNumber>2</CircledNumber>,
    icon: <Image className={styles['divider-image']} src="/post_inspection/intro-2.png" />,
    subtitle: formatString(POST_INSPECTION_FLOW_STRINGS['intro.divider'][1]),
  },
  {
    top: <CircledNumber>3</CircledNumber>,
    icon: <Image className={styles['divider-image']} src="/post_inspection/intro-3.png" />,
    subtitle: formatString(POST_INSPECTION_FLOW_STRINGS['intro.divider'][2]),
  },
];

const Intro = ({ stepResource, onSaveAndNext, stepConfigs, stepConfig, flow }) => {
  const history = useHistory();

  const areNonHomeowerFixesStepsCompleted = stepConfigs
    ?.filter((stepConfig) => stepConfig.key !== 'homeowner-fixes' && !stepConfig.isHidden)
    .every((stepConfig) => stepConfig.status === 'Completed');

  /**
   * The homeowner cannot go back to this step once the report is approved.
   */
  if (areNonHomeowerFixesStepsCompleted) {
    history.push(
      `${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${stepConfig.home.propertyId}/setup-flow?${QUERY_PARAMS.REPORT_APPROVED}=true`
    );

    return null;
  }

  const { homeListingDetailsModel, report } = stepResource;

  const inspectionType = getInspectionType(flow);

  const { inspectorSelfie, inspectorWithHomeowner } = homeListingDetailsModel?.inspection?.inspectionDetail || {};

  const inspectionCompletedOn = new Date(
    homeListingDetailsModel?.inspection?.scheduledOn || homeListingDetailsModel?.inspection?.completedOn
  );

  const weekdayOrYesterday = isYesterday(legacyParse(inspectionCompletedOn))
    ? 'Yesterday'
    : `${formatDateTime({ dateTime: legacyParse(inspectionCompletedOn), format: DATE_FORMATS.DAY })}`;

  const homeownerName = homeListingDetailsModel?.owner?.firstName;

  const concierge = Object.values(homeListingDetailsModel?.employeeAssignments)?.find(
    (employeeAssignmentItem) =>
      employeeAssignmentItem.employeeAssignment.assignmentType === EmployeeAssignmentTypes.Concierge
  );

  const homeownerGrowth = Object.values(homeListingDetailsModel?.employeeAssignments)?.find(
    (employeeAssignmentItem) =>
      employeeAssignmentItem.employeeAssignment.assignmentType === EmployeeAssignmentTypes.HomeownerGrowth
  );

  const assessedBy = concierge ?? homeownerGrowth;
  let formattedAssessedBy = {};

  if (assessedBy) {
    formattedAssessedBy = {
      ...assessedBy.employee,
      ...assessedBy.user,
      profileImage: assessedBy.user.profileImageUrl,
      bannerImage: assessedBy.employee.bannerImageUrl,
      jobTitle: EMPLOYEE_TITLES.HomeImprovementsSpecialist,
      topText: formatString(POST_INSPECTION_FLOW_STRINGS['intro.assessed_by'], {
        assessedOn: formatDateTime({ dateTime: legacyParse(new Date(report.publishedOn)), format: 'MMM do' }),
      }),
    };
  }

  let title;

  if (inspectionType.isOnboarding || inspectionType.isAdoptedLease) {
    title = formatString(POST_INSPECTION_FLOW_STRINGS['intro.title.onboarding'], {
      homeownerName,
      weekdayOrYesterday,
    });
  } else if (inspectionType.isPreMoveOut) {
    title = formatString(POST_INSPECTION_FLOW_STRINGS['intro.title.pre_move_out'], {
      homeownerName,
    });
  } else if (inspectionType.isMoveOut) {
    title = POST_INSPECTION_FLOW_STRINGS['intro.title.move_out'];
  }

  let bannerImageUrl;
  if (inspectorWithHomeowner) {
    bannerImageUrl = inspectorWithHomeowner.url;
  } else if (inspectorSelfie) {
    bannerImageUrl = inspectorSelfie.url;
  } else {
    bannerImageUrl = homeListingDetailsModel?.basicInfo?.bannerImageUrl;
  }

  const leaseEndOn = homeListingDetailsModel?.leases?.find((lease) => lease.basicInfo.isMovingOutLease)?.basicInfo
    .leaseEndOn;

  return (
    <PostInspectionContentLayout
      stepConfigs={stepConfigs}
      stepConfig={stepConfig}
      title={title}
      showCityBackground
      employee={formattedAssessedBy}
      isLogoVisible={false}
    >
      <div className="flex justify-center mb-2xl">
        <PostInspectionContentLayout.CtaButton onClick={() => onSaveAndNext()}>
          {formatString(POST_INSPECTION_FLOW_STRINGS['intro.next'])}
        </PostInspectionContentLayout.CtaButton>
      </div>

      {inspectionType.isMoveOut && (
        <Text className="p-sm border border-solid border-green bg-green-light-bg rounded mb-2xl">
          {formatString(POST_INSPECTION_FLOW_STRINGS['intro.greenBox'], {
            date: formatDateTime({ dateTime: legacyParse(new Date(leaseEndOn)), format: DATE_FORMATS.MONTH_DAY_SHORT }),
          })}
        </Text>
      )}

      {bannerImageUrl && (
        <div className={styles['image-container']}>
          <Image src={bannerImageUrl} />
        </div>
      )}

      <Page.OffsetGrid ignoreOffset={[SPACE_TYPES.MD, SPACE_TYPES.LG, SPACE_TYPES.XL]}>
        <MultipleBelongEmployeeProfileView employees={[formattedAssessedBy]} />
      </Page.OffsetGrid>
      <Space value={SPACE_TYPES.XXXL} />

      {inspectionType.isOnboarding && (
        <>
          <Text variant="h3" fontWeight="semibold">
            {formatString(POST_INSPECTION_FLOW_STRINGS['intro.divider_section.title'])}
          </Text>
          <div className="mt-xs mb-xl">{formatString(POST_INSPECTION_FLOW_STRINGS['intro.divider_section.body'])}</div>

          {/* Desktop divider row */}
          <div className={styles['desktop-divider-row-container']}>
            <DividerRow items={dividerRowItems} />
          </div>

          {/* Mobile divider row */}
          <div className={styles['mobile-divider-row-container']}>
            <Carousel pagingDotsClassName="green">
              {dividerRowItems.map((item, index) => (
                <DividerRow.Item key={index} item={item} defaultMobileIconSize={false} />
              ))}
            </Carousel>
          </div>
        </>
      )}
    </PostInspectionContentLayout>
  );
};

Intro.propTypes = propTypes;

export default Intro;
