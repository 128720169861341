import { RefObject, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatMoney } from '@belong/common';
import { IconAlert } from '@belong/icons';
import { ButtonSelector, Divider, Text } from '@belong/ui';
import { MaintenanceGroupBundleModel, MaintenanceModel, MaintenanceWorkOrderModel } from 'api/models';
import Collapsible from 'components/Collapsible/Collapsible';
import NameValueTable from 'components/NameValueTable/NameValueTable';
import { useMaintenanceHasPendingQuoteApproval } from 'maintenance/hooks/useMaintenanceHasPendingQuoteApproval';
import { useMaintenanceProposalOptions } from 'maintenance/hooks/useMaintenanceProposalOptions';
import { usePreloadGroupMaintenanceTasks } from 'maintenance/hooks/usePreloadGroupMaintenanceTasks';
import { fetchTaskByTaskId } from 'store/redux/maintenance/actions';
import { selectMaintenanceById } from 'store/redux/maintenance/selectors';
import { MaintenanceApprovalAndResponsibility } from '../MaintenanceApprovalAndResponsibility/MaintenanceApprovalAndResponsibility';
import { MaintenanceCost } from '../MaintenanceCost/MaintenanceCost';
import { useMaintenanceCostDisplayData } from '../MaintenanceCost/use-maintenance-cost-display-data';
import { SplitInfoBox } from '../split-infobox/split-infobox';

type ProposalsProps = {
  maintenance: MaintenanceModel;
  userRole: 'Resident' | 'Homeowner';
  fetchTask: () => Promise<void>;
  showScheduleCall?: boolean;
  onScheduleHomecareSpecialistCall: () => void;
  accountResponsibilityRef: RefObject<HTMLDivElement>;
};

type ProposalContentProps = {
  bundle: MaintenanceGroupBundleModel;
  userRole: 'Resident' | 'Homeowner';
  fetchTask: () => Promise<void>;
  showScheduleCall?: boolean;
  onScheduleHomecareSpecialistCall?: () => void;
  accountResponsibilityRef: RefObject<HTMLDivElement>;
};

type ProposalsContentProps = {
  maintenance: MaintenanceModel;
  userRole: 'Resident' | 'Homeowner';
  fetchTask: () => Promise<void>;
  showScheduleCall?: boolean;
  onScheduleHomecareSpecialistCall?: () => void;
  accountResponsibilityRef: RefObject<HTMLDivElement>;
};

type WorkOrderStatusItem = {
  color: string;
  label: string;
};

const workOrderStatus: Record<MaintenanceWorkOrderModel['status'], WorkOrderStatusItem> = {
  AppointmentScheduled: {
    color: 'bg-blue',
    label: 'Appointment Scheduled',
  },
  AssigningPro: {
    color: 'text-blue',
    label: 'Assigning Pro',
  },
  Canceled: {
    color: 'text-red',
    label: 'Canceled',
  },
  Completed: {
    color: 'text-green',
    label: 'Completed',
  },
  New: {
    color: 'text-blue',
    label: 'New',
  },
  PreparingInvoice: {
    color: 'text-blue',
    label: 'Preparing Invoice',
  },
  PreparingQuote: {
    color: 'text-blue',
    label: 'Preparing Quote',
  },
  WaitingForQuoteApproval: {
    color: 'text-red',
    label: 'Approval Required',
  },
  WorkInProgress: {
    color: 'text-blue',
    label: 'Work In Progress',
  },
};

const ProposalContent = ({
  bundle,
  userRole,
  fetchTask,
  showScheduleCall,
  onScheduleHomecareSpecialistCall,
  accountResponsibilityRef,
}: ProposalContentProps) => {
  const optionItems = bundle.items?.filter((item) => !item.isTBD) ?? [];
  const approvedItem = bundle.items.find((item) =>
    item.maintenancePayments.some((payment) => payment.consentStatus === 'Approved')
  );
  const [selectedProposalId, setSelectedProposalId] = useState(
    approvedItem
      ? approvedItem.maintenance.uniqueId
      : optionItems.length > 0
      ? optionItems[0].maintenance.uniqueId
      : undefined
  );
  const options = useMaintenanceProposalOptions(optionItems, selectedProposalId);
  const dispatch = useDispatch();

  const proposal = bundle.items.find((item) => item.maintenance.uniqueId === selectedProposalId);
  const taskOptionIndex = bundle.items.findIndex((item) => item.maintenance.uniqueId === selectedProposalId);
  const taskOptionNumber = bundle.items.length > 1 && taskOptionIndex !== -1 ? taskOptionIndex + 1 : undefined;
  const proposalMaintenanceUniqueId = proposal?.maintenance?.uniqueId;
  const proposalMaintenance = useSelector(selectMaintenanceById(proposalMaintenanceUniqueId));
  const recommendedOption = options.some((option) => option.tag === 'Recommended');
  const hasMultipleOptions = optionItems.length > 1;
  const aProposalHasBeenApproved = bundle.items.some((item) =>
    item.maintenancePayments.some((payment) => payment.consentStatus === 'Approved')
  );

  const onChangeSelectedProposal = (value: string) => {
    setSelectedProposalId(value);
  };

  const onConsentChange = async () => {
    setTimeout(async () => {
      for (const item of bundle.items) {
        const maintenanceUniqueId = item.maintenance?.uniqueId;
        try {
          await dispatch(fetchTaskByTaskId(maintenanceUniqueId));
        } catch (error) {
          console.log(error);
        }
      }
    }, 3000);

    fetchTask();
  };

  useEffect(() => {
    if (proposalMaintenanceUniqueId && !proposalMaintenance) {
      try {
        dispatch(fetchTaskByTaskId(proposalMaintenanceUniqueId));
      } catch (error) {
        console.log(error);
      }
    }
  }, [dispatch, proposalMaintenanceUniqueId, proposalMaintenance]);

  return (
    <div>
      {options.length > 1 && (
        <div className={recommendedOption ? 'mt-sm mx-sm' : 'mx-sm'}>
          <ButtonSelector
            options={options}
            onChange={onChangeSelectedProposal}
            itemWidth="fluid"
            itemHeight="extra-small"
            value={selectedProposalId}
          />
        </div>
      )}

      {proposalMaintenance && (
        <MaintenanceCost
          task={proposalMaintenance}
          taskOptionNumber={hasMultipleOptions ? taskOptionNumber : undefined}
          userRole={userRole}
          onConsentChange={onConsentChange}
          accountResponsibilityRef={accountResponsibilityRef}
          showScheduleCall={showScheduleCall}
          onScheduleHomecareSpecialistCall={onScheduleHomecareSpecialistCall}
          showItemPrices={false}
          showQuoteApprovalSection={!aProposalHasBeenApproved}
        />
      )}

      {proposalMaintenance &&
        proposalMaintenance?.payments?.some(
          (payment) => payment.consentStatus === 'Approved' || payment.consentStatus === 'Rejected'
        ) && (
          <div className="mx-sm">
            <Divider borderStyle="dashed" />
            <div className="mt-sm">
              <MaintenanceApprovalAndResponsibility
                collapsable={false}
                task={proposalMaintenance}
                title=""
                payments={proposalMaintenance.payments.filter(
                  (payment) => payment.consentStatus === 'Approved' || payment.consentStatus === 'Rejected'
                )}
              />
            </div>
          </div>
        )}
    </div>
  );
};

const ProposalsContent = ({
  maintenance,
  userRole,
  fetchTask,
  showScheduleCall,
  onScheduleHomecareSpecialistCall,
  accountResponsibilityRef,
}: ProposalsContentProps) => {
  const { subtasks } = maintenance;
  const totalPriceRange =
    maintenance.priceRange.lowerBoundTotal === maintenance.priceRange.upperBoundTotal
      ? formatMoney(maintenance.priceRange.lowerBoundTotal)
      : `${formatMoney(maintenance.priceRange.lowerBoundTotal)} - ${formatMoney(
          maintenance.priceRange.upperBoundTotal
        )}`;
  const { financePlanId, hasEligibleOpenBalance } = useMaintenanceCostDisplayData({
    task: maintenance,
    userRole,
  });

  if (subtasks?.length > 1) {
    return (
      <>
        {subtasks.map((subtask, index) => {
          const subtasksHasBeenApproved = subtask.items.some((item) =>
            item.maintenancePayments.some((payment) => payment.consentStatus === 'Approved')
          );
          const primaryTask = subtask.items.find(
            (item) =>
              item.maintenance.workOrder?.createdReason === 'Primary' ||
              item.maintenance.workOrder?.createdReason === 'AdditionalWork'
          );
          const approvedItem = subtask.items?.find((item) =>
            item.maintenancePayments?.some((payment) => payment.consentStatus === 'Approved')
          );
          const bundleOptions = subtask.items?.length > 1 ? subtask.items.filter((item) => !item.isTBD) : [];
          const bundleOptionsLabel = bundleOptions.length > 1 ? `${bundleOptions.length} Options` : '';
          const optionsAvailableLabel = !subtasksHasBeenApproved ? ' Available' : '';
          const workStatus = subtask.items.some(
            (item) => item.maintenance.workOrder?.status === 'WaitingForQuoteApproval'
          )
            ? 'WaitingForQuoteApproval'
            : primaryTask.maintenance.workOrder?.status;
          const workOrderStatusValue = approvedItem ? approvedItem.maintenance.workOrder?.status : workStatus;

          let priceRange;
          if (subtask.priceRange.lowerBoundTotal > 0) {
            priceRange =
              subtask.priceRange.lowerBoundTotal === subtask.priceRange.upperBoundTotal
                ? formatMoney(subtask.priceRange.lowerBoundTotal)
                : `${formatMoney(subtask.priceRange.lowerBoundTotal)} - ${formatMoney(
                    subtask.priceRange.upperBoundTotal
                  )}`;
          }

          return (
            <>
              <Collapsible
                key={index}
                collapsed={false}
                title={primaryTask.maintenance.summary}
                defaultClassName="bg-light-gray border-solid border-l-1 rounded mt-md border-gray"
                createMarginAndPaddingClassName="pt-sm"
                className="pt-sm px-sm"
                titleComponent={
                  <div className="flex flex-col w-full pb-sm">
                    <div className="flex flex-row justify-between w-full">
                      <Text className="capitalize" fontWeight="semibold" variant="body">
                        {primaryTask.maintenance.summary}
                      </Text>
                      {priceRange && (
                        <div className="pr-sm">
                          <span className="text-body font-semibold">{priceRange}</span>
                        </div>
                      )}
                    </div>
                    <span className="text-p1 font-semibold">
                      {workOrderStatusValue && (
                        <span className={workOrderStatus[workOrderStatusValue].color}>
                          {workOrderStatus[workOrderStatusValue].label}
                        </span>
                      )}
                      <span className="font-regular">
                        {workOrderStatusValue && bundleOptionsLabel
                          ? ` | ${bundleOptionsLabel}${optionsAvailableLabel}`
                          : bundleOptionsLabel}
                      </span>
                    </span>
                  </div>
                }
                inner={
                  <ProposalContent
                    key={index}
                    bundle={subtask}
                    userRole={userRole}
                    fetchTask={fetchTask}
                    showScheduleCall={showScheduleCall}
                    onScheduleHomecareSpecialistCall={onScheduleHomecareSpecialistCall}
                    accountResponsibilityRef={accountResponsibilityRef}
                  />
                }
              />
            </>
          );
        })}
        <NameValueTable
          boldValue
          items={[
            {
              name: 'Total',
              value: totalPriceRange,
            },
          ]}
        />
        {!maintenance.isLegacyInvoicing && userRole === 'Homeowner' && (
          <div className="mt-md">
            <SplitInfoBox
              financePlanId={financePlanId}
              hasOpenBalance={hasEligibleOpenBalance}
              isMaintenanceCompleted={maintenance.result === 'Completed'}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {subtasks.map((subtask, index) => (
        <div className="mt-md" key={index}>
          <ProposalContent
            bundle={subtask}
            userRole={userRole}
            fetchTask={fetchTask}
            showScheduleCall={showScheduleCall}
            onScheduleHomecareSpecialistCall={onScheduleHomecareSpecialistCall}
            accountResponsibilityRef={accountResponsibilityRef}
          />
        </div>
      ))}
      <NameValueTable
        boldValue
        items={[
          {
            name: 'Total',
            value: totalPriceRange,
          },
        ]}
      />
    </>
  );
};

export const Proposals = ({
  maintenance,
  userRole,
  fetchTask,
  showScheduleCall,
  onScheduleHomecareSpecialistCall,
  accountResponsibilityRef,
}: ProposalsProps) => {
  const { subtasks } = maintenance;
  const hasPendingQuotesToApprove = useMaintenanceHasPendingQuoteApproval(userRole, maintenance);
  const { financePlanId, hasEligibleOpenBalance } = useMaintenanceCostDisplayData({
    task: maintenance,
    userRole,
  });

  usePreloadGroupMaintenanceTasks(maintenance);

  return (
    <>
      <Collapsible
        title="Scope Of Work"
        collapsed={false}
        titleComponent={
          hasPendingQuotesToApprove ? (
            <div className="flex flex-row">
              <Text className="capitalize" fontWeight="semibold" variant="h3">
                Scope Of Work
              </Text>
              <IconAlert width={26} className="ml-xs" />
            </div>
          ) : null
        }
        inner={
          subtasks?.length > 1 ? (
            <ProposalsContent
              maintenance={maintenance}
              userRole={userRole}
              fetchTask={fetchTask}
              showScheduleCall={showScheduleCall}
              onScheduleHomecareSpecialistCall={onScheduleHomecareSpecialistCall}
              accountResponsibilityRef={accountResponsibilityRef}
            />
          ) : (
            <>
              <ProposalContent
                bundle={subtasks[0]}
                userRole={userRole}
                fetchTask={fetchTask}
                showScheduleCall={showScheduleCall}
                onScheduleHomecareSpecialistCall={onScheduleHomecareSpecialistCall}
                accountResponsibilityRef={accountResponsibilityRef}
              />
              {!maintenance.isLegacyInvoicing && userRole === 'Homeowner' && (
                <SplitInfoBox
                  financePlanId={financePlanId}
                  hasOpenBalance={hasEligibleOpenBalance}
                  isMaintenanceCompleted={maintenance.result === 'Completed'}
                />
              )}
            </>
          )
        }
      />
    </>
  );
};
