import { useWindowSize, BREAKPOINTS_WIDTHS } from '@belong/common';
import { Text, Image, ButtonBase } from '@belong/ui';
import { EmployeeAssignmentTypes } from 'models/enums';
import { getEmployeeAssignment } from 'pages/PostInspectionFlow/utils';
import { EMPLOYEE_TITLES } from 'strings/common.strings';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import PostInspectionContentLayout from '../../../PostInspectionContentLayout/PostInspectionContentLayout';

export const Seasonality = ({ stepResource, stepConfig, stepConfigs, onSaveAndNext, onPrevious }) => {
  const { employeeAssignments } = stepResource || {};
  const { user, employee } = getEmployeeAssignment({
    employeeAssignments,
    assignmentType: EmployeeAssignmentTypes.ResidentGrowth,
  });
  const headOfPricingProfile = {
    ...user,
    bannerImageUrl: employee?.bannerImageUrl,
    jobTitle: EMPLOYEE_TITLES.ResidentGrowth,
  };

  const { width } = useWindowSize();
  const isMobile = width < BREAKPOINTS_WIDTHS.LG;

  return (
    <PostInspectionContentLayout
      showCityBackground
      stepConfig={stepConfig}
      stepConfigs={stepConfigs}
      title={POST_INSPECTION_FLOW_STRINGS['pricing.seasonality.title']}
      employee={headOfPricingProfile}
      noOverlay
    >
      <Text variant="h3" className="mb-xs" fontWeight="semibold">
        {POST_INSPECTION_FLOW_STRINGS['pricing.seasonality.listing_title']}
      </Text>
      <Text className="mb-2xl">{POST_INSPECTION_FLOW_STRINGS['pricing.seasonality.listing_subtitle']}</Text>

      <div className="w-full">
        <Image
          src={isMobile ? '/pricing-flow/seasonality_mobile.svg' : '/pricing-flow/seasonality.svg'}
          alt=""
          className="mb-xl"
        />
      </div>

      <div className="rounded p-sm mt-xl mb-2xl border border-solid border-green bg-green-translucent-light">
        <Text className="mb-xs" fontWeight="semibold">
          {POST_INSPECTION_FLOW_STRINGS['pricing.seasonality.impact_title']}
        </Text>
        <Text className="mb-xs"> {POST_INSPECTION_FLOW_STRINGS['pricing.seasonality.impact_content']}</Text>
      </div>

      <div className="hidden md:flex justify-center mt-2xl mb-3xl">
        <PostInspectionContentLayout.CtaButton onClick={() => onSaveAndNext()}>
          {POST_INSPECTION_FLOW_STRINGS['pricing.seasonality.cta']}
        </PostInspectionContentLayout.CtaButton>
      </div>

      <div className="fixed bottom-0 w-full flex md:hidden -ml-2sm z-fullscreen">
        <ButtonBase className="h-2xl flex-1 bg-navy" onClick={onPrevious}>
          <Text fontWeight="semibold" className="text-white">
            Previous
          </Text>
        </ButtonBase>

        <ButtonBase className="h-2xl flex-1 bg-green disabled:bg-gray" onClick={() => onSaveAndNext()}>
          <Text fontWeight="semibold" className="text-white">
            Next
          </Text>
        </ButtonBase>
      </div>
    </PostInspectionContentLayout>
  );
};
