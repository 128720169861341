/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect } from 'react';
import Measure from 'react-measure';
import classNames from 'classnames/bind';
import Button, { BUTTON_TYPES, BUTTON_SIZES } from 'components/Button/Button';
import Logo from 'components/Logo/Logo';
import TextBubbleWithUserProfile from 'components/TextBubbleWithUserProfile/TextBubbleWithUserProfile';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import { Col, Grid, Row } from 'forkedlibraries/react-bootstrap';
import { useModal } from 'hooks/useModal';
import { last } from 'lodash-es';
import { ImprovementImportanceModal } from 'pages/PostInspectionFlow/ImprovementImportanceModal/ImprovementImportanceModal';
import { THEME_CONSTS } from 'themes/themes';
import PostInspectionFlowProgressBar from '../PostInspectionFlowProgressBar/PostInspectionFlowProgressBar';
import styles from './PostInspectionContentLayout.module.css';

// REPLACE WITH CONTENT LAYOUT!
const cx = classNames.bind(styles);

const PostInspectionContentLayoutContext = React.createContext();

const PostInspectionContentLayout = ({
  title,
  children,
  showCityBackground,
  employee,
  actions = [],
  headerBorder = true,
  topFiller,
  stepConfigs,
  stepConfig,
  noMinHeight,
  noOverlay,
  hasCustomFixedPageHeader,
  isLogoVisible = true,
}) => {
  const [ctaProps, setCtaProps] = useState(null);
  const [isModalOpen, setModalClose] = useModal(false);

  return (
    <PostInspectionContentLayoutContext.Provider
      value={{
        setCtaProps,
        ctaProps,
      }}
    >
      {ctaProps && (
        <PostInspectionContentLayout.FixedPageHeader
          headerBorder={headerBorder}
          isLogoVisible={isLogoVisible}
          actions={[
            ...actions,
            <div key={0} className={cx('header-cta-container')}>
              <Button
                disabled={ctaProps.disabled}
                buttonType={BUTTON_TYPES.DEFAULT}
                size={BUTTON_SIZES.FIT}
                onClick={ctaProps.onClick}
              >
                {ctaProps.children}
              </Button>
            </div>,
          ]}
        />
      )}
      <Grid>
        <Row className={cx('flex-row', { hasCustomFixedPageHeader })}>
          <Col lg={2} md={1} smHidden xsHidden className={cx('col-z-index')} style={{ minHeight: 0 }}>
            {!noOverlay && <div className={cx('overlay', 'left')} />}
          </Col>
          <Col className={cx('layout', { noMinHeight })} lg={8} md={10} xs={12}>
            {topFiller && topFiller}
            <PostInspectionFlowProgressBar stepConfigs={stepConfigs} stepConfig={stepConfig} />
            <div className={cx('title')}>
              <TextBubbleWithUserProfile employee={employee} text={title} />
            </div>
            {children}
            {!noMinHeight && <Space value={SPACE_TYPES.XXXXXL} />}
          </Col>
          <Col lg={2} md={1} smHidden xsHidden className={cx('col-z-index')}>
            {!noOverlay && <div className={cx('overlay', 'right')} />}
          </Col>
        </Row>
      </Grid>
      {showCityBackground && (
        <div className={cx('fixed-background')}>
          <img src="/city_images/city_side_fold_full.svg" alt="city" />
        </div>
      )}
      {isModalOpen && <ImprovementImportanceModal show={isModalOpen} onHide={setModalClose} />}
    </PostInspectionContentLayoutContext.Provider>
  );
};

PostInspectionContentLayout.CtaButton = ({ children, onClick, disabled, extraSpace = 0, ...rest }) => {
  const contentLayoutContext = useContext(PostInspectionContentLayoutContext);
  const [buttonBottom, setButtonBottom] = useState(null);

  useEffect(() => {
    if (buttonBottom) {
      contentLayoutContext.setCtaProps({
        disabled,
        children,
        onClick,
        buttonBottom: buttonBottom + extraSpace,
      });
    }
    // Don't like checking on all this deps. But only way to get updated values inside the onClick.
  }, [children, disabled, buttonBottom, onClick]);

  return (
    <Measure
      bounds
      onResize={({ bounds }) => {
        // bounds.bottom is relative to scrollY, we want relative to scrollY = 0
        setButtonBottom(window.scrollY + bounds.bottom);
      }}
    >
      {({ measureRef }) => (
        <div ref={measureRef} className={cx('cta-container')}>
          <Button
            buttonType={BUTTON_TYPES.DEFAULT}
            size={BUTTON_SIZES.FLUID_LONG_RESPONSIVE}
            onClick={onClick}
            disabled={disabled}
            {...rest}
          >
            {children}
          </Button>
        </div>
      )}
    </Measure>
  );
};
const fixedHeaderHeight = 60;
PostInspectionContentLayout.FixedPageHeader = ({ actions, headerBorder = true, isLogoVisible = true }) => {
  const contentLayoutContext = useContext(PostInspectionContentLayoutContext);
  const animationBoundary = contentLayoutContext?.ctaProps?.buttonBottom;
  const [visible, setVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > animationBoundary - fixedHeaderHeight) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [animationBoundary]);

  return (
    <>
      <div
        role="dialog"
        aria-label="Information Navigation"
        className={cx('header-background', {
          hide: !visible,
          show: !!visible,
          hideBorder: !headerBorder,
        })}
      />
      <div
        className={cx('fixed', {
          hide: !visible,
        })}
      >
        <Grid>
          <div className={cx('fixed-header-wrapper')}>
            <div className={cx('logo-wrappers')}>{isLogoVisible && <Logo theme={THEME_CONSTS.DARK} />}</div>
            <div className={cx('desktop-actions-wrapper')}>
              {actions.map((action, index) => (
                <div className={cx('action')} key={index}>
                  {action}
                </div>
              ))}
            </div>
            {/* Show only the last action, since there is no generic way to handle multiple
            actions for mobile */}
            <div className={cx('mobile-actions-wrapper')}>{last(actions)}</div>
          </div>
        </Grid>
      </div>
    </>
  );
};

export default PostInspectionContentLayout;
