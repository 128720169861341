import React, { Component } from 'react';
import classNames from 'classnames/bind';
import HomeInfo from 'components/display/Home/HomeInfo/HomeInfo';
import UnitBasicInfo from 'models/common/UnitBasicInfo';
import PropTypes from 'prop-types';
import styles from './TitleSection.module.css';

const cx = classNames.bind(styles);

class TitleSection extends Component {
  static propTypes = {
    unitBasicInfo: PropTypes.instanceOf(UnitBasicInfo).isRequired,
    address: PropTypes.node.isRequired,
    smallTitle: PropTypes.bool,
    favoriteButton: PropTypes.node,
  };

  static defaultProps = {
    smallTitle: false,
    favoriteButton: null,
  };

  render() {
    const { unitBasicInfo, address, smallTitle, favoriteButton } = this.props;

    return (
      <div className={cx('title-section-wrapper')}>
        <div className={cx('title-section', { smallTitle })}>
          <div className={cx('subtitle')}>{address}</div>
          <div className={cx('title')}>
            <HomeInfo unitBasic={unitBasicInfo} showUnitLevel={false} wrap />
          </div>
        </div>
        {favoriteButton && <div className={cx('favorite-button-wrapper')}>{favoriteButton}</div>}
      </div>
    );
  }
}

export default TitleSection;
