import React from 'react';
import classNames from 'classnames/bind';
import { Grid, Row, Col } from 'forkedlibraries/react-bootstrap';
import PropTypes from 'prop-types';
import { ALIGN_ITEMS } from '../MaintenanceStatus/UserActionMaintenance/UserProfilePopout/UserProfilePopout';
import ResidentTileWithStatus from '../ResidentTileWithStatus/ResidentTileWithStatus';
import styles from './ResidentTileListWithStatus.module.css';

const cx = classNames.bind(styles);

const ResidentTileListWithStatus = ({ residents }) => {
  return (
    <Grid>
      <Row>
        {residents.map((resident, index) => (
          <Col key={index} xs={12} sm={6} className={cx('resident')}>
            <ResidentTileWithStatus alignProfileItems={ALIGN_ITEMS.ALIGN_LEFT} {...resident} />
          </Col>
        ))}
      </Row>
    </Grid>
  );
};

ResidentTileListWithStatus.propTypes = {
  residents: PropTypes.array.isRequired,
  singleColumnDesktop: PropTypes.bool,
};

ResidentTileListWithStatus.defaultProps = {
  singleColumnDesktop: false,
};

export default ResidentTileListWithStatus;
