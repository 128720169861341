import classNames from 'classnames/bind';
import GeneralIcon, { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import Label from 'components/Label/Label';
import ShadowMediaCard from 'components/ShadowMediaCard/ShadowMediaCard';
import { Text } from 'design-system';
import { MaintenanceAssessment, MaintenanceRequester, SubOwnerVisibility } from 'models/enums';
import PropTypes from 'prop-types';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import { ResidentResponsibilityLabel, SharedResponsibilityLabel } from '../Labels/Labels';
import styles from './ImprovementCard.module.css';

const cx = classNames.bind(styles);

const ImprovementCard = ({
  assessmentLabel = true,
  borderRadius = true,
  containerClassName = '',
  customTopLeft = null,
  height = 300,
  improvement,
  mediaContainerClassName = '',
  onClick = null,
  price = null,
  isPreventative = false,
  context = 'improvement-report',
}) => {
  const { maintenance, maintenancePayments } = improvement;
  const homeownerPayment = maintenancePayments.find((payment) => payment.paidBy === MaintenanceRequester.Homeowner);
  const residentPayment = maintenancePayments.find((payment) => payment.paidBy === MaintenanceRequester.Resident);
  const residentResponsiblity = residentPayment?.percentage === 100;
  const sharedResponsiblity = homeownerPayment?.percentage > 0 && residentPayment?.percentage > 0;

  const improvementMedia = maintenance.media?.filter(
    (mediaItem) => ![SubOwnerVisibility.Consumer, SubOwnerVisibility.Internal].includes(mediaItem.subOwnerVisibility)
  );

  const getLabel = () => {
    if (context === 'improvement-report') {
      return maintenance.assessment === MaintenanceAssessment.Required
        ? MaintenanceAssessment.Required
        : POST_INSPECTION_FLOW_STRINGS['approvals.optional'];
    }

    return maintenance.assessment;
  };

  return (
    <ShadowMediaCard
      onClick={onClick}
      containerClassName={containerClassName}
      mediaContainerClassName={mediaContainerClassName}
      borderRadius={borderRadius}
      height={height}
      imageUrl={improvementMedia?.[0]?.url}
      bottomRight={price}
      topLeft={
        customTopLeft ? (
          customTopLeft(maintenance)
        ) : (
          <div className={cx('top-left-container')}>
            {assessmentLabel && (
              <Label>
                {maintenance.requestedBy === MaintenanceRequester.Belong
                  ? getLabel()
                  : POST_INSPECTION_FLOW_STRINGS['improvements.by-you']}
              </Label>
            )}
            {sharedResponsiblity && !isPreventative && (
              <div className={cx('label-container')}>
                <SharedResponsibilityLabel />
              </div>
            )}
            {residentResponsiblity && !isPreventative && (
              <div className={cx('label-container')}>
                <ResidentResponsibilityLabel />
              </div>
            )}
            <div className={cx('label-container')}>
              <Text className={cx('image-text')} fontWeight="semibold">
                {maintenance.summary}
              </Text>
            </div>
          </div>
        )
      }
      topRight={
        homeownerPayment?.flagged && (
          <div className={cx('top-right-container')}>
            <GeneralIcon icon={GENERAL_ICONS.ICON_FLAG} />
            <span className={cx('flag-text')}>{POST_INSPECTION_FLOW_STRINGS['improvements.flag.text']}</span>
          </div>
        )
      }
    />
  );
};

ImprovementCard.propTypes = {
  assessmentLabel: PropTypes.bool,
  borderRadius: PropTypes.bool,
  containerClassName: PropTypes.string,
  customTopLeft: PropTypes.func,
  height: PropTypes.number,
  improvement: PropTypes.object.isRequired,
  mediaContainerClassName: PropTypes.string,
  onClick: PropTypes.func,
  price: PropTypes.node,
  isPreventative: PropTypes.bool,
  context: PropTypes.oneOf(['improvement-report', 'pro-service']),
};

export default ImprovementCard;
