import { useSelector } from 'react-redux';
import { MaintenanceModel } from 'api/models';
import { find } from 'lodash-es';
import { selectUser } from 'store/redux/user/selectors';

export const useRequestAccountResponsibility = (task: MaintenanceModel, isRenter: boolean) => {
  const user = useSelector(selectUser);

  if (!task) {
    return false;
  }

  // responsibility currently requires both the resident / homeowner  user to display the mock.
  // Also there can be no actual cost object. Once there is a real cost there is no account responsibility.
  if (task.actualCost || !task.estimatedCost) {
    return false;
  }

  if (!task.payments?.length) {
    return false;
  }

  const renterResponsibility = find(task.payments, (payment) => payment.paidBy === 'Resident');
  const homeOwnerResponsibility = find(task.payments, (payment) => payment.paidBy === 'Homeowner');

  if (renterResponsibility?.consentStatus === 'Required' && isRenter) {
    if (renterResponsibility.paidByUser.userId === user.userId) {
      return true;
    }
    return false;
  } else if (homeOwnerResponsibility?.consentStatus === 'Required' && !isRenter) {
    return true;
  }

  return false;
};
