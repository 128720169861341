import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import { COOKIES_CONFIG, PATHS, BASE_PATHS, useModal } from '@belong/common';
import { Text } from '@belong/ui';
import { PageViewTracker } from 'analytics';
import { ChatWidget } from 'components/ChatWidget/ChatWidget';
import FullPageSpinner from 'components/FullPageSpinner/FullPageSpinner';
import MetaNoIndex from 'components/Metatags/MetaNoIndex';
import { getHomePath, getPortfolioPath } from 'consts/account-menu-items';
import ToDoComponents from 'containercomponents/ToDoComponents/ToDoComponents';
import Page from 'layouts/Page/Page';
import { AppModal } from 'lease-signing-flow/components/app-modal/app-modal';
import { selectWasAppModalShown, showAppModal } from 'lease-signing-flow/store/app';
import { Milestone, UserToDoTypes } from 'models/enums';
import { parseCookies } from 'nookies';
import PropTypes from 'prop-types';
import { fetchAccountProperties } from 'store/redux/homeowner-accounts/actions';
import { selectAccountProperties } from 'store/redux/homeowner-accounts/selectors';
import {
  fetchFavoriteAccountsHomes,
  fetchResidentBookmarks,
  fetchResidentLeases,
  fetchTourRequests,
} from 'store/redux/renter-accounts/actions';
import {
  selectBookmarks,
  selectFavoriteHomesAccounts,
  selectAccountAllLeases,
} from 'store/redux/renter-accounts/selectors';
import { fetchRegions } from 'store/redux/settings';
import { fetchTracking, trackSplashScreenViewed } from 'store/redux/tracking/actions';
import { fetchAllFlows, fetchReports, fetchUserClaims, fetchUserToDos, getUserDevices } from 'store/redux/user/actions';
import {
  _selectUser,
  selectUser,
  selectUserClaims,
  selectUserToDos,
  selectUserFlows,
} from 'store/redux/user/selectors';
import { formatAddress } from 'utils/formatAddress';
import InitialEstimateModal from './Components/InitialEstimateModal/InitialEstimateModal';
import { SplashScreenModal } from './Components/SplashScreenModal/SplashScreenModal';
import { AccountsLayout } from './Components/accounts-layout/accounts-layout';
import { HOMEOWNER_PAGES, PAGES, PAGES_CONFIG, RENTER_ACCOUNT_PAGES } from './Pages/pages';

function Accounts({ location, match, history }) {
  const { params } = match;
  const dispatch = useDispatch();
  const cookies = parseCookies();
  const [isSplashScreenModalOpen, openSplashScreenModal, closeSplashScreenModal] = useModal();
  const [isAppModalShow, setIsAppModalShow] = useState(false);
  const [isInitialModalOpen, openInitialModal, closeInitialModal] = useModal();
  const [estimatedUnit, setEstimatedUnit] = useState();
  const [estimatedAddress, setEstimatedAddress] = useState();
  const [estimatedAddressModel, setEstimatedAddressModel] = useState();
  const placematRouteMatch = useRouteMatch({
    path: `${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/:propertyId?/setup-flow`,
    exact: true,
  });

  const wasAppModalShown = useSelector(selectWasAppModalShown);
  const user = useSelector(selectUser);
  const userObject = useSelector(_selectUser);
  const userClaims = useSelector(selectUserClaims);
  const homeownerProperties = useSelector(selectAccountProperties);
  const accountAllLeases = useSelector(selectAccountAllLeases);
  const bookmarks = useSelector(selectBookmarks);
  const favorites = useSelector(selectFavoriteHomesAccounts);
  const todos = useSelector(selectUserToDos);
  const homeownerFlows = useSelector(selectUserFlows);

  const childrenProps = {
    location,
    match,
    history,
    userClaims,
    homeownerProperties,
  };

  const { section, subSection } = params;
  const { isHomeOwner, isResident } = userClaims || {};
  const comeFromTheMobileApp = Boolean(cookies[COOKIES_CONFIG.MOBILE_APP.name] === 'true');

  const trackSplashScreen = async () => {
    await dispatch(trackSplashScreenViewed(userObject?.id));
    openSplashScreenModal();
  };

  const openPricingModal = (unit, address) => {
    if (unit) {
      const formattedAddress = formatAddress(address, unit?.basicInfo?.unitNumber);

      setEstimatedUnit(unit);
      setEstimatedAddress(formattedAddress);
      setEstimatedAddressModel(address);
      openInitialModal();
    }
  };

  const handleAppModalDismiss = () => {
    dispatch(showAppModal());
    setIsAppModalShow(false);
  };

  useEffect(() => {
    async function mountComponent() {
      try {
        const claims = await dispatch(fetchUserClaims());
        const requests = [];

        requests.push(dispatch(fetchUserToDos()));
        requests.push(dispatch(fetchRegions()));

        if (claims.isHomeOwner) {
          requests.push(dispatch(fetchAllFlows()));
          requests.push(dispatch(fetchAccountProperties()));
          requests.push(dispatch(fetchReports()));
        }

        if (claims.isResident) {
          requests.push(dispatch(fetchResidentLeases()));
          requests.push(dispatch(fetchResidentBookmarks()));
          requests.push(dispatch(fetchTourRequests()));
          requests.push(dispatch(fetchFavoriteAccountsHomes()));
        }
        await Promise.all(requests);
      } catch (e) {
        console.error(e);
      }
    }

    mountComponent();
  }, []);

  useEffect(() => {
    const getTrackingData = async () => {
      const filteredHomes = homeownerProperties.some((property) =>
        property.units.find((unit) =>
          [
            Milestone.ShowReady,
            Milestone.FullListingPublished,
            Milestone.MoveInReady,
            Milestone.ResidentMovedIn,
            Milestone.ResidentGaveMoveOutNotice,
            Milestone.ListingRepublished,
            Milestone.ManagingAdoptedResidents,
          ].includes(unit.basicInfo.currentMilestone)
        )
      );

      if (filteredHomes) {
        const devices = await dispatch(getUserDevices());

        if (devices.length === 0 && isHomeOwner && userObject?.id) {
          const events = await dispatch(fetchTracking(`eventName=splash_screen_viewed&userId=${userObject?.id}`));
          if (events.length === 0) return trackSplashScreen();

          const orderedEvents = events.sort((a, b) => new Date(b.metadata.viewDate) - new Date(a.metadata.viewDate));
          const today = new Date();
          const limitDate = new Date(today.setDate(today.getDate() - 21));

          if (new Date(orderedEvents[0].metadata.viewDate) < limitDate) {
            trackSplashScreen();
          }
        }
      }
    };

    if (homeownerProperties) {
      getTrackingData();
    }
  }, [homeownerProperties, userObject, user, isHomeOwner, trackSplashScreen]);

  useEffect(() => {
    // This is just to prevent showing the modal until the app is released and stable.
    const isAppModalEnabled = false;

    if (isResident && !isHomeOwner && !wasAppModalShown && isAppModalEnabled) {
      setIsAppModalShow(true);
    }
  }, [isResident, wasAppModalShown]);

  if (
    !userClaims ||
    (isHomeOwner && !homeownerProperties) ||
    (isResident && (!accountAllLeases || !bookmarks || !favorites))
  ) {
    return (
      <Page
        headerMainProps={{
          fixed: true,
          navigationComponents: [],
        }}
        footer={false}
      >
        <MetaNoIndex />
        <FullPageSpinner />
      </Page>
    );
  }

  if (!section) {
    if (homeownerProperties?.length) {
      return <Redirect to={getPortfolioPath(homeownerProperties)} />;
    } else if (accountAllLeases?.length) {
      return <Redirect to={getHomePath(accountAllLeases)} />;
    } else if (bookmarks?.length) {
      return <Redirect to={PATHS.RESIDENTS_ACCOUNT_TOURS_AND_APPS} />;
    } else if (favorites?.length) {
      return <Redirect to={PATHS.RESIDENTS_ACCOUNT_FAVORITES} />;
    }

    return <Redirect to={PATHS.ACCOUNTS_PROFILE} />;
  }

  if (!subSection && section === 'homeowners') {
    if (homeownerProperties?.length) {
      return <Redirect to={getPortfolioPath(homeownerProperties)} />;
    }

    return <Redirect to={PATHS.ACCOUNTS_PROFILE} />;
  } else if (!subSection && section === 'residents') {
    if (accountAllLeases?.length) {
      return <Redirect to={getHomePath(accountAllLeases)} />;
    } else if (bookmarks?.length) {
      return <Redirect to={PATHS.RESIDENTS_ACCOUNT_TOURS_AND_APPS} />;
    } else if (favorites?.length) {
      return <Redirect to={PATHS.RESIDENTS_ACCOUNT_FAVORITES} />;
    }

    return <Redirect to={PATHS.ACCOUNTS_PROFILE} />;
  }

  const PAGE_ROUTES = [...PAGES];

  if (homeownerProperties?.length) {
    PAGE_ROUTES.push(...HOMEOWNER_PAGES);
  }

  PAGE_ROUTES.push(...RENTER_ACCOUNT_PAGES);

  function getVisibleToDos() {
    return todos?.filter(({ type }) => {
      if (type === 'HomeownerPlacementsQualificationFlowOpen') {
        return false;
      }

      if (type === 'W9FlowOpen' && placematRouteMatch?.isExact) {
        const w9Flows = homeownerFlows?.filter((homeownerFlow) => homeownerFlow.flowType === 'W9');

        if (w9Flows?.length === 1) {
          return false;
        }

        const createdOrInProgressW9Flows = w9Flows?.some((homeownerFlow) =>
          ['Created', 'InProgress'].includes(homeownerFlow.status)
        );

        if (!createdOrInProgressW9Flows) {
          return false;
        }
      }

      if (type === 'HomeownerSetupFlowOpen' && placematRouteMatch?.isExact && homeownerProperties?.length === 1) {
        return false;
      }

      return true;
    });
  }

  const visibleToDos = getVisibleToDos()?.map((todo) => {
    // Adding steps to AgreementFlowOpen todos to get the right current aggrement flow step.
    if (todo.type === UserToDoTypes.AgreementFlowOpen) {
      const steps = homeownerFlows.find((flow) => flow.uniqueId === todo.metadata.flowId)?.steps || [];
      return { ...todo, steps };
    }

    return todo;
  });

  return (
    <Fragment>
      <ChatWidget tags={['Page: Accounts']} isEnabled />
      <Page
        header={!comeFromTheMobileApp}
        headerMainProps={{
          fixed: true,
        }}
        footer={false}
      >
        <MetaNoIndex />
        <AccountsLayout comeFromTheMobileApp={comeFromTheMobileApp} userClaims={userClaims} section={section}>
          <div className="relative flex-grow ml-0 lg:ml-[calc(255px+3rem)]">
            {visibleToDos.length !== 0 && (
              <div className="mb-xl">
                <div className="rounded py-md px-sm border border-solid border-red bg-red-translucent/5">
                  <div className="flex justify-between items-start mb-sm">
                    <Text fontWeight="semibold" variant="h3">
                      To Do's
                    </Text>
                  </div>
                  <div className="flex flex-col gap-xs">
                    {visibleToDos.map((todo) => (
                      <ToDoComponents
                        {...todo}
                        properties={homeownerProperties}
                        user={user}
                        openPricingModal={openPricingModal}
                        todos={visibleToDos}
                        key={todo.type}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
            {PAGE_ROUTES.map((pageConfig) => {
              const PageComponent = pageConfig.component;
              return (
                <Route
                  key={pageConfig.path}
                  path={pageConfig.path}
                  isExact={!!pageConfig.isExact}
                  render={() => <PageComponent {...childrenProps} />}
                  data={pageConfig.data}
                />
              );
            })}
            <Route
              path={`${PATHS.ACCOUNT_BILLS}/:id?`}
              render={({ match: _match }) => {
                const paymentsPath = PATHS.ACCOUNTS_PAYMENTS;

                let to = isHomeOwner ? PAGES_CONFIG.EARNINGS.path : paymentsPath;
                if (_match.params.id) {
                  to = `${isHomeOwner ? PAGES_CONFIG.EARNINGS.path : paymentsPath}/${_match.params.id}`;
                }

                return <Redirect to={to} />;
              }}
            />
          </div>
        </AccountsLayout>

        <SplashScreenModal isOpen={isSplashScreenModalOpen} onDismiss={closeSplashScreenModal} />
        <AppModal isOpen={isAppModalShow} onDismiss={handleAppModalDismiss} />
        <InitialEstimateModal
          address={estimatedAddress}
          unit={estimatedUnit}
          isOpen={isInitialModalOpen}
          onDismiss={closeInitialModal}
          addressModel={estimatedAddressModel}
        />

        <div className="mb-7xl" />
      </Page>
    </Fragment>
  );
}

Accounts.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default PageViewTracker(Accounts);
