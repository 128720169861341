import { useForm } from 'react-final-form';
import { Money } from '@belong/ui';
import classNames from 'classnames/bind';
import Checkbox from 'components/Checkbox/Checkbox';
import { BREAKPOINTS_WIDTHS } from 'consts/breakpoints';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import { Box, Flex, Text } from 'design-system';
import { useWindowSize } from 'hooks/useWindowSize';
import PropTypes from 'prop-types';
import styles from './BundleItemOption.module.css';

const cx = classNames.bind(styles);

const BundleItemOption = ({ isImprovementSelected = false, isSelected, onSelect, option }) => {
  const form = useForm();
  const { width } = useWindowSize();

  const isMobile = width <= BREAKPOINTS_WIDTHS.SM;

  const handleOptionSelect = () => {
    form.change('isSelected', true);

    if (!isSelected) {
      onSelect(option.costId);
    }
  };

  return (
    <ButtonBase className={cx('wrapper')} onClick={handleOptionSelect}>
      <Box className={cx(option.media.length ? 'content' : 'no-media-grid')} minHeight={108} padding="sm">
        {isSelected && isImprovementSelected ? (
          <>
            <Box className={cx('selected')} />
            <Box className={cx('selected-background')} />
          </>
        ) : (
          <Box className={cx('not-selected')} />
        )}
        <Flex
          alignItems={['flex-end', 'center']}
          className={cx('selection')}
          justifyContent={['flex-end', 'flex-start']}
        >
          <Checkbox
            alignCenter
            label={isMobile ? 'SELECT' : ''}
            maskCheckedState={!isImprovementSelected}
            onSelect={handleOptionSelect}
            useOnChangeFormToHandle
            value={isSelected}
          />
        </Flex>
        {!!option.media.length && (
          <Flex className={cx('picture')} flexShrink="0" marginRight="sm">
            <img className={cx('image')} alt="" src={option.media[0].url} />
          </Flex>
        )}
        <Flex alignItems="flex-start" className={cx('description')} flexDirection="column">
          <Box marginBottom="xs">
            <Text className={cx([!isImprovementSelected && 'disabled-option'])} fontWeight="semibold">
              {option.title}
            </Text>
          </Box>
          <Text className={cx([!isImprovementSelected && 'disabled-option'])} fontSize="p1">
            {option.description}
          </Text>
        </Flex>
        <Flex
          alignItems={['center', 'flex-end']}
          className={cx('pricing')}
          flexDirection={['row', 'column']}
          justifyContent={['space-between', 'center']}
          marginTop={['sm', 0]}
        >
          <Box paddingTop={['1px', 0]}>
            <Text className={cx([!isImprovementSelected && 'disabled-option'])} fontWeight="semibold">
              <Money format="DOLLARS_NO_CENTS" value={option.optionDisplayCost} className="font-semibold" />
            </Text>
          </Box>
        </Flex>
      </Box>
    </ButtonBase>
  );
};

BundleItemOption.propTypes = {
  isImprovementSelected: PropTypes.bool,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  option: PropTypes.object.isRequired,
};

export { BundleItemOption };
