import { EmployeeAssignmentTypes } from 'models/enums';
import { ReportType } from 'models/enums/typings';
import { EMPLOYEE_TITLES } from 'strings/common.strings';

type Params = {
  listings: any[];
  reportType: keyof typeof ReportType;
  flow: any;
};

type Result = {
  employee: any;
};

export const useEmployee = ({ listings, flow, reportType }: Params): Result => {
  if (reportType === ReportType.Improvements) {
    const { flowSubType, homeUniqueId } = flow.configuration;

    const currentListing = listings.find(
      (listing) => listing.homeListingDetailsModel.basicInfo.unitId === homeUniqueId
    );

    const {
      homeListingDetailsModel: { employeeAssignments },
    } = currentListing;

    const employeeCharge =
      flowSubType === 'Onboarding' // todo: check if these contant can be fetched from Swagger types
        ? EmployeeAssignmentTypes.HomeownerGrowth
        : EmployeeAssignmentTypes.Concierge;

    const improvementsReportMainPointOfContact = employeeAssignments.find(
      (employeeAssignment) => employeeAssignment.employeeAssignment.assignmentType === employeeCharge
    );

    const employee = {
      ...improvementsReportMainPointOfContact.employee,
      ...improvementsReportMainPointOfContact.user,
      bannerImage: improvementsReportMainPointOfContact.employee.bannerImageUrl,
      jobTitle: EMPLOYEE_TITLES[improvementsReportMainPointOfContact.employeeAssignment.assignmentType],
      profileImage: improvementsReportMainPointOfContact.user.profileImageUrl,
    };

    return { employee };
  }

  // todo: complete this case once we migrate pricing report to post-inspection folder
  return undefined;
};
