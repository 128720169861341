import React from 'react';
import classNames from 'classnames/bind';
import { MAINTENANCE_TASK_MOBILE_PROPS } from 'containercomponents/MaintenanceTaskListContainerComponent/MaintenanceTasks/MaintenanceProps';
import styles from './MaintenanceTaskStatus.module.css';

const cx = classNames.bind(styles);

type MaintenanceTaskCostandStatusProps = {
  moreInfo: string;
  maintenanceTaskStatusLabel: string;
  mobileDisplay?: string;
};

const MaintenanceTaskCostandStatus = ({
  moreInfo,
  maintenanceTaskStatusLabel,
  mobileDisplay = MAINTENANCE_TASK_MOBILE_PROPS.NO_MOBILE,
}: MaintenanceTaskCostandStatusProps) => {
  let maintenanceText: React.ReactNode;

  if (maintenanceTaskStatusLabel === 'Complete' || maintenanceTaskStatusLabel === 'Completed') {
    maintenanceText = <div className={cx('bold', 'green')}>{maintenanceTaskStatusLabel}</div>;
  } else if (maintenanceTaskStatusLabel === 'Cancelled') {
    maintenanceText = <div className={cx('bold', 'gray')}>{maintenanceTaskStatusLabel}</div>;
  } else if (maintenanceTaskStatusLabel === 'InProgress') {
    maintenanceText = <div className={cx('bold')}>In Progress</div>;
  } else if (maintenanceTaskStatusLabel === 'PendingConsent') {
    maintenanceText = <div className={cx('bold', 'red')}>Waiting For Quote Approval</div>;
  } else {
    maintenanceText = <div className={cx('bold')}>{maintenanceTaskStatusLabel}</div>;
  }

  return (
    <div className={cx('maintenanceTaskCostandStatus', mobileDisplay)}>
      {maintenanceText}
      {moreInfo && <div className={cx('maintenanceTaskDate')}>{moreInfo}</div>}
    </div>
  );
};

export default MaintenanceTaskCostandStatus;
