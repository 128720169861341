import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import * as Scroll from 'react-scroll';
import { BASE_PATHS } from '@belong/common';
import classNames from 'classnames/bind';
import Button, { BUTTON_TYPES } from 'components/Button/Button';
import DividerRow from 'components/DividerRow/DividerRow';
import Icon, { ICONS } from 'components/Icon/Icon';
import Input from 'components/Input/Input';
import { LABEL_COLOR_TYPES } from 'components/Label/Label';
import LabeledList from 'components/LabeledList/LabeledList';
import Tooltip from 'components/Tooltip/Tooltip';
import Space, { SPACE_TYPES } from 'corecomponents/Space/Space';
import { Col, Row } from 'forkedlibraries/react-bootstrap';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { EmployeeAssignmentTypes } from 'models/enums';
import Home from 'models/home/Home';
import HomeFeatures from 'pages/HousePage/HomeFeatures/HomeFeatures';
import TitleSection from 'pages/HousePage/TitleSection/TitleSection';
import { QUERY_PARAMS } from 'pages/PostInspectionFlow/utils';
import PropTypes from 'prop-types';
import { formatString } from 'strings';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import { formatAddressWithCity } from 'utils/formatAddress';
import { EMPLOYEE_TITLES } from '../../../../../strings/common.strings';
import PostInspectionContentLayout from '../../../PostInspectionContentLayout/PostInspectionContentLayout';
import styles from './Features.module.css';

const cx = classNames.bind(styles);
const { scroller, Element } = Scroll;

const propTypes = {
  stepResource: PropTypes.object.isRequired,
  stepData: PropTypes.object.isRequired,
  onSaveAndNext: PropTypes.func.isRequired,
  stepConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepConfig: PropTypes.object.isRequired,
};

const Features = ({ stepResource, stepData, onSaveAndNext, stepConfigs, stepConfig }) => {
  const { homeListingDetailsModel } = stepResource;
  const inputRef = useRef(null);
  const [missedAnything, setMissedAnything] = useState(stepData?.neighborhoodDetails?.additionalFeaturesPostInspection);
  const history = useHistory();

  const areNonHomeowerFixesStepsCompleted = stepConfigs
    ?.filter((stepConfig) => stepConfig.key !== 'homeowner-fixes' && !stepConfig.isHidden)
    .every((stepConfig) => stepConfig.status === 'Completed');

  /**
   * The homeowner cannot go back to this step once the report is approved.
   */
  if (areNonHomeowerFixesStepsCompleted) {
    history.push(
      `${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${stepConfig.home.propertyId}/setup-flow?${QUERY_PARAMS.REPORT_APPROVED}=true`
    );

    return null;
  }

  useEffect(() => {
    setMissedAnything(stepData?.neighborhoodDetails?.additionalFeaturesPostInspection);
  }, [stepData]);

  const concierge = Object.values(homeListingDetailsModel?.employeeAssignments)?.find(
    (employeeAssignmentItem) =>
      employeeAssignmentItem.employeeAssignment.assignmentType === EmployeeAssignmentTypes.Concierge
  );

  const homeownerGrowth = Object.values(homeListingDetailsModel?.employeeAssignments)?.find(
    (employeeAssignmentItem) =>
      employeeAssignmentItem.employeeAssignment.assignmentType === EmployeeAssignmentTypes.HomeownerGrowth
  );

  const assessedBy = concierge ?? homeownerGrowth;
  let formattedAssessedBy = {};

  if (assessedBy) {
    formattedAssessedBy = {
      ...assessedBy.employee,
      ...assessedBy.user,
      profileImage: assessedBy.user.profileImageUrl,
      bannerImage: assessedBy.employee.bannerImageUrl,
      jobTitle: EMPLOYEE_TITLES.HomeImprovementsSpecialist,
    };
  }

  const home = new Home(stepResource.homeListingDetailsModel);
  const { address, basicInfo, featuresMediaSectionNames = [], qualities = [] } = home;

  const uniqueQualities = qualities
    .filter((quality) => quality.level === 'Good')
    .map((quality) => ({
      text: quality.type,
      labelColor: LABEL_COLOR_TYPES.GREEN,
      labelText: formatString(POST_INSPECTION_FLOW_STRINGS['features.labeled_list.qualities.label_text']),
    }));

  const quirks = qualities
    .filter((quality) => quality.level === 'Bad')
    .map((quality) => ({
      text: quality.type,
      labelColor: LABEL_COLOR_TYPES.GRAY,
      labelText: formatString(POST_INSPECTION_FLOW_STRINGS['features.labeled_list.quirks.label_text']),
    }));

  const handleSubmit = (values) => {
    onSaveAndNext({
      ...values,
      neighborhoodDetails: { ...values?.neighborhoodDetails, additionalFeaturesPostInspection: missedAnything },
    });
  };

  const dividerItems = [
    {
      icon: <Icon icon={ICONS.GAS.DEFAULT} responsive />,
      title: String(featuresMediaSectionNames.length),
      subtitle: formatString(POST_INSPECTION_FLOW_STRINGS['features.divider.features_and_appliances'], {
        br: <br />,
      }),
    },
  ];

  if (uniqueQualities?.length) {
    dividerItems.push({
      icon: <Icon icon={ICONS.QUALITY_DOCUMENT.DEFAULT} responsive />,
      title: String(uniqueQualities.length),
      subtitle: formatString(POST_INSPECTION_FLOW_STRINGS['features.divider.unique_qualities'], {
        br: <br />,
      }),
    });
  }

  dividerItems.push({
    icon: <Icon icon={ICONS.SAFE.DEFAULT} responsive />,
    title: String(quirks.length),
    subtitle: formatString(POST_INSPECTION_FLOW_STRINGS['features.divider.unique_quirks'], { br: <br /> }),
  });

  return (
    <PostInspectionContentLayout
      stepConfigs={stepConfigs}
      stepConfig={stepConfig}
      title={formatString(POST_INSPECTION_FLOW_STRINGS['features.title'])}
      employee={formattedAssessedBy}
      isLogoVisible={false}
      showCityBackground
    >
      <p>{formatAddressWithCity(address, address.unitNumber)}</p>
      <TitleSection unitBasicInfo={basicInfo} />
      <Space value={SPACE_TYPES.XL} />

      <DividerRow items={dividerItems} />
      <Space value={SPACE_TYPES.XL} />

      <div className={cx('buttons-container')}>
        <PostInspectionContentLayout.CtaButton onClick={() => handleSubmit(stepData)}>
          {missedAnything !== ''
            ? formatString(POST_INSPECTION_FLOW_STRINGS['features.next_something_wrong'])
            : formatString(POST_INSPECTION_FLOW_STRINGS['features.next'])}
        </PostInspectionContentLayout.CtaButton>

        <Space value={SPACE_TYPES.LG} />

        <Button
          buttonType={BUTTON_TYPES.TEXT}
          onClick={() => {
            scroller.scrollTo('missedAnything', {
              duration: 1000,
              smooth: true,
            });
            if (inputRef?.current) {
              inputRef.current.focus();
            }
          }}
        >
          {formatString(POST_INSPECTION_FLOW_STRINGS['features.something_off'])}
        </Button>
      </div>
      <Space value={SPACE_TYPES.XXXL} />

      <p className={cx('feature-section-title')}>Features</p>
      <Space value={SPACE_TYPES.LG} />

      <HomeFeatures home={home} showMedia={false} />
      <Space value={SPACE_TYPES.XXL} />

      <Row>
        {uniqueQualities.length > 0 && (
          <Col sm={6} className={cx('first-column')}>
            <LabeledList
              title={formatString(POST_INSPECTION_FLOW_STRINGS['features.labeled_list.qualities.title'])}
              tooltip={
                <Tooltip className={cx('spacing-left')}>
                  {formatString(POST_INSPECTION_FLOW_STRINGS['features.tooltip.qualities'])}
                </Tooltip>
              }
              items={uniqueQualities}
            />
          </Col>
        )}
        {quirks.length > 0 && (
          <Col sm={6}>
            <LabeledList
              title={formatString(POST_INSPECTION_FLOW_STRINGS['features.labeled_list.quirks.title'])}
              tooltip={
                // Sometimes content wouldn't fit in the window under any position, so only
                // solution is to reduce width.
                <Tooltip className={cx('spacing-left')} contentStyle={{ width: 280 }}>
                  {formatString(POST_INSPECTION_FLOW_STRINGS['features.tooltip.quirks'])}
                </Tooltip>
              }
              items={quirks}
            />
          </Col>
        )}
      </Row>
      {(uniqueQualities.length > 0 || quirks.length > 0) && <Space value={SPACE_TYPES.XXL} />}

      {/* Single input, handling in react state */}
      <FormLayout.Section sectionTitle={formatString(POST_INSPECTION_FLOW_STRINGS['features.form_section.title'])}>
        <Space value={SPACE_TYPES.SM} />
        <Input
          inputRef={inputRef}
          name="missedAnything"
          placeholder={formatString(POST_INSPECTION_FLOW_STRINGS['features.form_section.placeholder'])}
          value={missedAnything}
          onChange={(event) => {
            const { value } = event.target;
            setMissedAnything(value);
          }}
        />
      </FormLayout.Section>
      <Element name="missedAnything" />
    </PostInspectionContentLayout>
  );
};

Features.propTypes = propTypes;

export default Features;
