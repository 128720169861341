import React from 'react';
import { MobileMenuContext } from 'components/HeaderMain/HeaderMain';
import RouterHeaderNavLink from 'components/HeaderMain/RouterHeaderNavLink/RouterHeaderNavLink';
import AccountHeaderContainer from 'containers/AccountHeaderContainer/AccountHeaderContainer';
import { ContactUs } from 'containers/ContactUs/ContactUs';
import { flatten } from 'lodash-es';
import { EmployeeAssignmentTypes } from 'models/enums';
import PropTypes from 'prop-types';

export const extractHomesFromProperties = (properties) => {
  return flatten(
    properties.map((property) =>
      property.units.map((home) => ({
        homeId: home.basicInfo.unitId,
        propertyId: property.basicInfo.propertyId,
        propertyType: property.basicInfo.propertyType,
        address: {
          ...property.address,
          unitNumber: home.basicInfo.unitNumber,
        },
      }))
    )
  );
};

export const HeaderLinks = ({ to }) => {
  const { hideMobileMenu } = React.useContext(MobileMenuContext);

  return [
    <ContactUs key="ContactUs" />,
    <RouterHeaderNavLink key="save" label="SAVE & EXIT" to={to} onClick={hideMobileMenu} />,
  ];
};

HeaderLinks.propTypes = {
  to: PropTypes.string,
};

export const LandingHeaderLinks = [
  <ContactUs key="ContactUs" />,
  <AccountHeaderContainer key="AccountHeaderContainer" />,
];

export const getInspectionType = (flow) => {
  const { flowSubType, isAdoptedLease } = flow?.configuration || {};
  if (!flowSubType) return false;
  return {
    isAdoptedLease,
    isOnboarding: flowSubType === 'Onboarding', // adopted lease comes as onboarding
    isMoveOut: flowSubType === 'MoveOut',
    isPreMoveOut: flowSubType === 'PreMoveOut',
  };
};

export const checkIsFirstPricingReport = (report) => {
  if (!report) return true;
  return report.subType === 'PricingPostInspection';
};

export const mainEmployeePointOfContact = (isOnboarding) =>
  isOnboarding ? EmployeeAssignmentTypes.HomeownerGrowth : EmployeeAssignmentTypes.Concierge;

// The only reason to do that is because Lily asked to "come back" if the changes don't pay off.
// Besides, we don't currently support feature flags.
export const groupRecommendedImprovements = true;

export const QUERY_PARAMS = {
  REPORT_APPROVED: 'reportApproved',
};

export const getEmployeeAssignment = ({ employeeAssignments, assignmentType }) =>
  employeeAssignments?.find((e) => e?.employeeAssignment?.assignmentType === assignmentType) ||
  employeeAssignments?.[0];
