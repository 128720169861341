import { useSelector } from 'react-redux';
import { Text } from '@belong/ui';
import type { InspectionModel, MaintenanceModel, MaintenanceRecurrenceModel } from 'api/models';
import { EmptyTaskList } from 'maintenance/components/empty-task-list/empty-task-list';
import { UnitConsentPendingTasksSection } from 'maintenance/components/unit-consent-pending-tasks-section/UnitConsentPendingTasksSection';
import { UnitTasksSection } from 'maintenance/components/unit-tasks-section/unit-tasks-section';
import { STRINGS } from 'maintenance/strings';
import type { ProServiceView } from 'maintenance/types';
import {
  getRecurringTasksByUnit,
  getTasksByUnit,
  getTasksWithInspection,
  shouldRequestResponsibilityConsent,
} from 'maintenance/utils';
import HomeownerAccountProperty from 'models/homeownerAccounts/HomeownerAccountProperty';
import HomeownerAccountUnit from 'models/homeownerAccounts/HomeownerAccountUnit';
import RenterAccountLease from 'models/renterAccounts/RenterAccountLease';
import { selectAccountProperties } from 'store/redux/homeowner-accounts/selectors';
import { selectInspections } from 'store/redux/inspections';
import { selectHomeownerTasks, selectRecurringTasks, selectRentTasks } from 'store/redux/maintenance/selectors';
import { selectLeases } from 'store/redux/renter-accounts/selectors';
import { formatFirstLine } from 'utils/formatAddress';

type Props = {
  showMaintenanceModal: (task: MaintenanceModel | MaintenanceRecurrenceModel) => () => void;
  view?: ProServiceView;
};

export function PropertyTaskSectionList({ showMaintenanceModal, view = 'homeOwner' }: Props) {
  const properties = useSelector<unknown, HomeownerAccountProperty[]>(selectAccountProperties);
  const leases = useSelector<unknown, RenterAccountLease[]>(selectLeases);
  const inspections = useSelector<unknown, InspectionModel[]>(selectInspections);
  const homeownerTasks = useSelector(selectHomeownerTasks);
  const residentTasks = useSelector(selectRentTasks);
  const recurringTasks = useSelector(selectRecurringTasks);

  const tasksByUnit = getTasksByUnit(view === 'homeOwner' ? homeownerTasks : residentTasks);
  const recurringTasksByUnit = getRecurringTasksByUnit(recurringTasks);

  if (view === 'resident' && !leases.length) {
    return <EmptyTaskList type="non-recurring" />;
  }

  if (view === 'resident') {
    return (
      <div className="contents">
        {leases?.map((lease) => {
          const address = lease.unitInfo.unitNumber
            ? `${lease.address.streetAddress}, ${STRINGS.unit} ${lease.unitInfo.unitNumber}`
            : lease.address.streetAddress;

          const tasksWithInspection = getTasksWithInspection(tasksByUnit[lease.unitInfo.unitId], inspections);

          return (
            <div className="mb-3xl last-of-type:pb-3xl last-of-type:mb-3xl" key={lease.leaseInfo.basicInfo.leaseId}>
              <UnitTasksSection
                address={address}
                recurringTasks={recurringTasksByUnit[lease.unitInfo.unitId]}
                showMaintenanceModal={showMaintenanceModal}
                tasks={tasksWithInspection}
                unit={lease.unitInfo}
                view="resident"
              />
            </div>
          );
        })}
      </div>
    );
  }

  const propertiesWithPendingResponsibilityConsent = properties.reduce((acc, property) => {
    const unitsWithConsentPendingTasks = [];
    property.units.forEach((unit: HomeownerAccountUnit) => {
      if (unit.isPrimaryOwner) {
        const unitTasks = tasksByUnit[unit.basicInfo.unitId];
        const pendingTasks = unitTasks?.filter((task) => shouldRequestResponsibilityConsent(task, false)) || [];
        if (pendingTasks.length > 0) {
          unitsWithConsentPendingTasks.push(unit);
        }
      }
    });

    if (unitsWithConsentPendingTasks.length > 0) {
      acc.push(property);
    }
    return acc;
  }, []);

  console.log(propertiesWithPendingResponsibilityConsent);

  return (
    <div className="contents">
      {propertiesWithPendingResponsibilityConsent.length > 0 && (
        <>
          <Text fontWeight="semibold" variant="h3">
            Repairs That Need Your Approval
          </Text>
          {propertiesWithPendingResponsibilityConsent.map((property) => {
            return property.units.map((unit: HomeownerAccountUnit) => {
              const pendingConsentTasks =
                tasksByUnit[unit.basicInfo.unitId]?.filter((task) => shouldRequestResponsibilityConsent(task, false)) ||
                [];
              const address = formatFirstLine(property?.address, unit?.basicInfo?.unitNumber);
              return (
                <div className="mb-3xl last-of-type:pb-3xl last-of-type:mb-3xl" key={property.propertyId}>
                  <UnitConsentPendingTasksSection
                    address={address}
                    showMaintenanceModal={showMaintenanceModal}
                    tasks={pendingConsentTasks}
                  />
                </div>
              );
            });
          })}
        </>
      )}

      {properties.map((property) =>
        property.units.map((unit: HomeownerAccountUnit) => {
          if (!unit.isPrimaryOwner) {
            return null;
          }

          const address = formatFirstLine(property?.address, unit?.basicInfo?.unitNumber);

          const tasksWithInspection = getTasksWithInspection(tasksByUnit[unit.basicInfo.unitId], inspections);

          return (
            <div className="mb-3xl last-of-type:pb-3xl last-of-type:mb-3xl" key={unit.basicInfo.unitId}>
              <UnitTasksSection
                address={address}
                property={property}
                recurringTasks={recurringTasksByUnit[unit.basicInfo.unitId]}
                showMaintenanceModal={showMaintenanceModal}
                tasks={tasksWithInspection}
                unit={unit}
              />
            </div>
          );
        })
      )}
    </div>
  );
}
