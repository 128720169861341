import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { COOKIES_CONFIG } from '@belong/common';
import { IconClose } from '@belong/icons';
import { ButtonBase, MediaGallery, Spinner } from '@belong/ui';
import {
  AddressModel,
  EmployeeAssignmentDetailModel,
  InspectionModel,
  MaintenanceMediaModel,
  MaintenanceModel,
  ReportDetailModel,
} from 'api/models';
import classNames from 'classnames/bind';
import clsx from 'clsx';
import Button from 'components/Button/Button';
import { BUTTON_TYPES } from 'components/Button/buttonTypes';
import Collapsible from 'components/Collapsible/Collapsible';
import Grid from 'components/Grid/Grid';
import { LABEL_COLOR_TYPES } from 'components/Label/labelTypes';
import config from 'config/config';
import { MaintenanceAccountResponsibilityBanner } from 'containercomponents/MaintenanceStatus/MaintenanceAccountResponsibility/MaintenanceAccountResponsibilityBanner';
import { MaintenanceCost } from 'containercomponents/MaintenanceStatus/MaintenanceCost/MaintenanceCost';
import { MaintenanceHomecareSpecialist } from 'containercomponents/MaintenanceStatus/MaintenanceHomecareSpecialist/MaintenanceHomecareSpecialist';
import styles from 'containercomponents/MaintenanceStatus/MaintenanceStatus.module.css';
import { MaintenanceStatusFooter } from 'containercomponents/MaintenanceStatus/MaintenanceStatusFooter/maintenance-status-footer';
import { MaintenanceBalance } from 'containercomponents/MaintenanceStatus/ModalMaintenanceBalance/ModalMaintenanceBalance';
import { ModalMaintenanceSummary } from 'containercomponents/MaintenanceStatus/ModalMaintenanceSummary/ModalMaintenanceSummary';
import ModalMaintenanceTitleModalSubtitleandLabels from 'containercomponents/MaintenanceStatus/ModalMaintenanceTitleModalSubtitleandLabels/ModalMaintenanceTitleModalSubtitleandLabels';
import MaintenanceCancelModal from 'containercomponents/Modals/MaintenanceCancelModal/MaintenanceCancelModal';
import Space from 'corecomponents/Space/Space';
import { SPACE_TYPES } from 'corecomponents/Space/spaceTypes';
import { useMaintenanceHasPendingQuoteApproval } from 'maintenance/hooks/useMaintenanceHasPendingQuoteApproval';
import { useRequestAccountResponsibility } from 'maintenance/hooks/useRequestAccountResponsibility';
import { RequestType } from 'models/enums';
import { parseCookies } from 'nookies';
import { fetchAccountUnitListings, fetchAccountProperties } from 'store/redux/homeowner-accounts/actions';
import { _selectAccountProperties, selectAccountUnitListings } from 'store/redux/homeowner-accounts/selectors';
import {
  fetchHOTaskByTaskId,
  fetchRenterTaskByTaskId,
  maintenanceCurrentClear,
  fetchImprovementReportByTaskId,
  fetchEmployees,
  clearTaskById,
} from 'store/redux/maintenance/actions';
import { selectCurrentTask } from 'store/redux/maintenance/selectors';
import { MAINTENACE_STATUS_MODAL_STRINGS } from 'strings/maintenance-status-modal.string';
import { formatMaintenanceStringsForTasks, getStringFromTime } from 'utils/formatMaintenance';
import { pluralizeWithoutValue } from 'utils/pluralize';
import { checkIfCurrentStatusIsSame } from 'utils/validation';
import { GroupEmployeeAssignments } from './GroupEmployeeAssignments/GroupEmployeeAssignments';
import { GroupHomeownerReceiptCosts } from './GroupHomeownerReceiptCosts/GroupHomeownerReceiptCosts';
import { GroupImprovementCards } from './GroupImprovementCards/GroupImprovementCards';
import { GroupResidentReceiptCosts } from './GroupResidentReceiptCosts/GroupResidentReceiptCosts';
import { MaintenanceApprovalAndResponsibility } from './MaintenanceApprovalAndResponsibility/MaintenanceApprovalAndResponsibility';
import { maintenanceCostStrings } from './MaintenanceCost/maintenance-cost.constants';
import { useMaintenanceCostDisplayData } from './MaintenanceCost/use-maintenance-cost-display-data';
import { MaintenanceScheduleComponent } from './MaintenanceScheduleComponent/MaintenanceScheduleComponent';
import { Proposals } from './Proposals/Proposals';
import { SplitInfoBox } from './split-infobox/split-infobox';

const { JWT_TOKEN } = COOKIES_CONFIG;
const cx = classNames.bind(styles);

export const { shouldDoIt, renterSplitText } = MAINTENACE_STATUS_MODAL_STRINGS;

const isRenterValidTypes = {
  Repair: RequestType.Repair,
  Upgrade: RequestType.Upgrade,
  Addition: RequestType.Addition,
  KeysAndAccess: RequestType.KeysAndAccess,
  HaulingAndStorage: RequestType.HaulingAndStorage,
};

const isHomeownerValidTypes = {
  Repair: RequestType.Repair,
  Upgrade: RequestType.Upgrade,
  Addition: RequestType.Addition,
  HaulingAndStorage: RequestType.HaulingAndStorage,
  Preventative: RequestType.Preventative,
};

const editableRequestTypes = (requestType, status, isRenter, recurring, requestedBy) => {
  if (status === 'New') {
    if (isRenter && requestedBy === 'Resident') {
      if (isRenterValidTypes[requestType]) {
        return true;
      }
    }

    if (!isRenter && requestedBy === 'Homeowner') {
      if (isHomeownerValidTypes[requestType]) {
        return true;
      }

      if (requestType === RequestType.Preventative && !!recurring) {
        return true;
      }
    }
  }

  return false;
};

const getFormattedAddress = (address: AddressModel) =>
  `${address.streetAddress}${address.unitNumber ? ` Unit ${address.unitNumber}` : ''},
  <span>${address.city}, ${address.state} ${address.zipcode}</span>`;

const showEditButton = (isRenter: boolean, task: MaintenanceModel) => {
  if (!task) {
    return false;
  }

  return editableRequestTypes(
    task.requestType,
    task.status,
    isRenter,
    task.recurrence?.repeatFrequencyDays,
    task.requestedBy
  );
};

type MaintenanceStatusProps = {
  taskId: string;
  inspection?: InspectionModel;
  onClosePress: () => void;
  history: RouteComponentProps['history'];
  isRenter?: boolean;
};

function MaintenanceStatus({ taskId, inspection, onClosePress, history, isRenter = false }: MaintenanceStatusProps) {
  const [homecareSpecialist, setHomecareSpecialist] = useState<EmployeeAssignmentDetailModel | undefined>();
  const [isLoading, setLoading] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [isRecurring, setIsRecurring] = useState(false);
  const propertiesData = useSelector(_selectAccountProperties);
  const listings = useSelector(selectAccountUnitListings);
  const [improvementsReport, setImprovementReport] = useState<ReportDetailModel | undefined>();
  const accountResponsibilityRef = useRef();

  const task = useSelector(selectCurrentTask) as unknown as MaintenanceModel;
  const { financePlanId, hasEligibleOpenBalance } = useMaintenanceCostDisplayData({
    task,
    userRole: isRenter ? 'Resident' : 'Homeowner',
  });
  const shouldRequestAccountResponsibility = useRequestAccountResponsibility(task, isRenter);
  const hasPendingQuoteApproval = useMaintenanceHasPendingQuoteApproval(isRenter ? 'Resident' : 'Homeowner', task);
  const isSpinnerVisible = !task?.uniqueId || isLoading;

  const dispatch = useDispatch();
  const taskIdentifier = task?.id;

  const fetchHomecareSpecialist = useCallback(async () => {
    try {
      if (taskIdentifier) {
        const _homecareSpecialist = await dispatch(fetchEmployees('Maintenance', taskIdentifier, 'HomecareSpecialist'));
        setHomecareSpecialist(_homecareSpecialist);
      }
    } catch (e) {
      console.log(e);
    }
  }, [dispatch, taskIdentifier]);

  const fetchTask = useCallback(async () => {
    const fetchTaskAction = isRenter ? fetchRenterTaskByTaskId : fetchHOTaskByTaskId;
    try {
      setLoading(true);
      dispatch(clearTaskById);
      const currentTask = (await dispatch(fetchTaskAction(taskId))) as unknown as MaintenanceModel;

      if (currentTask.requestType === RequestType.Group) {
        await dispatch(fetchAccountProperties);
        await dispatch(fetchAccountUnitListings);
        const reportsData = await dispatch(fetchImprovementReportByTaskId(taskId));
        setImprovementReport(reportsData);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [dispatch, isRenter, taskId]);

  useEffect(() => {
    fetchTask();

    return () => {
      dispatch(maintenanceCurrentClear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (taskIdentifier) {
      fetchHomecareSpecialist();
    }
  }, [fetchHomecareSpecialist, taskIdentifier]);

  const handleShowCancelModal = () => {
    setCancelModal(true);
  };

  const handleCloseModal = () => {
    fetchTask();
    setCancelModal(false);
    setIsRecurring(false);
  };

  const handleShowCancelRecurringModal = () => {
    handleShowCancelModal();
    setIsRecurring(true);
  };

  const handleShowAddTaskModal = () => {
    if (!task) return;

    if (isRenter && task.requestType) {
      const cookies = parseCookies();
      const jwtToken = cookies[JWT_TOKEN.name];

      window.open(`${config.smsAssistUrl}${jwtToken}`, '_blank');
    } else if (!isRenter && task.requestType) {
      history.push(`/homeowner/pro-services/${task.requestType}/${task.uniqueId}`);
    }
  };

  const scheduleHomecareSpecialistCall = () => {
    if (homecareSpecialist?.employee?.calendarLink) {
      const formattedLink = homecareSpecialist.employee.calendarLink.includes('https')
        ? homecareSpecialist.employee.calendarLink
        : `https://${homecareSpecialist.employee.calendarLink}`;
      return window.open(formattedLink, '_blank');
    }
  };

  const renderMaintenanceApprovalAndResponsibilityComponent = () => {
    const findOne = task.payments.find(
      (consent) => consent.consentStatus === 'Approved' || consent.consentStatus === 'Rejected'
    );

    if (!findOne) {
      return false;
    }

    const filteredPayments = task.payments.filter(
      (payment) => payment.consentStatus === 'Approved' || payment.consentStatus === 'Rejected'
    );

    return (
      <>
        <Space value={SPACE_TYPES.XL} />
        <MaintenanceApprovalAndResponsibility
          task={task}
          payments={filteredPayments}
          title={pluralizeWithoutValue(
            filteredPayments.length,
            MAINTENACE_STATUS_MODAL_STRINGS['approvalAndResponsibility.title']
          )}
        />
      </>
    );
  };

  const renderBottom = () => {
    if (!task) {
      return;
    }

    // if pending consent return null.
    if (cancelModal) {
      return null;
    }

    if (
      task.status === 'New' &&
      checkIfCurrentStatusIsSame(task.requestedBy, isRenter) &&
      task.requestCategory !== 'InHomeRepairs'
    ) {
      const menuItems = [];
      if (task.recurrence?.uniqueId) {
        menuItems.push({
          name: (
            <Button
              className={cx('margin-mobile', 'w-full flex justify-start px-sm')}
              label="CANCEL THIS VISIT"
              buttonType={BUTTON_TYPES.TEXT_DANGER}
              onClick={handleShowCancelModal}
            />
          ),
          key: 'cancel-request',
        });

        if (!task.recurrence?.isCancelled) {
          menuItems.push({
            name: (
              <Button
                className={cx('margin-mobile', 'w-full flex justify-start px-sm')}
                buttonType={BUTTON_TYPES.TEXT_DANGER}
                label="STOP RECURRING VISITS"
                onClick={handleShowCancelRecurringModal}
              />
            ),
            key: 'cancel-recurring',
          });
        }
      }

      if (showEditButton(isRenter, task)) {
        menuItems.push({
          name: (
            <Button
              className={cx('margin-mobile', 'w-full flex justify-start px-sm')}
              buttonType={BUTTON_TYPES.TEXT_DANGER}
              label="EDIT"
              onClick={handleShowAddTaskModal}
            />
          ),
          key: 'edit',
        });
      }

      menuItems.push({
        name: (
          <Button
            className={cx('margin-mobile', 'w-full flex justify-start px-sm')}
            label="CANCEL REQUEST"
            buttonType={BUTTON_TYPES.TEXT_DANGER}
            onClick={handleShowCancelModal}
          />
        ),
        key: 'cancel-visit',
      });

      return <MaintenanceStatusFooter menuActions={menuItems} isRenter={isRenter} />;
    }

    return <MaintenanceStatusFooter isRenter={isRenter} />;
  };

  const renderForEveryTask = () => {
    // const { showSpinnerFunction, isRenter, inspection, hideSpinnerFunction, task, improvementsReport } = this.props;
    const getAllLabels = [];

    if (!task.uniqueId) {
      return null;
    }

    // We temporarily got rid of recommendations. TODO: ADd them back.

    if (task.urgency === 'Urgent') {
      getAllLabels.push({
        label: 'Urgent',
        color: LABEL_COLOR_TYPES.RED,
      });
    }

    if (task.recurrence?.repeatFrequencyDays) {
      getAllLabels.push({
        label: getStringFromTime(task.recurrence.repeatFrequencyDays),
      });
    }

    if (task.result === 'Completed') {
      getAllLabels.push({
        label: formatMaintenanceStringsForTasks(task),
        color: LABEL_COLOR_TYPES.GREEN,
      });
    } else if (task.result === 'Cancelled') {
      getAllLabels.push({
        label: formatMaintenanceStringsForTasks(task),
        color: LABEL_COLOR_TYPES.GRAY_BORDER,
      });
    } else if (shouldRequestAccountResponsibility) {
      getAllLabels.push({
        label: 'Waiting For Quote Approval',
        color: LABEL_COLOR_TYPES.RED_BORDER,
      });
    } else {
      getAllLabels.push({
        label: formatMaintenanceStringsForTasks(task),
        color: LABEL_COLOR_TYPES.WHITE,
      });
    }

    const isGroupTask = task.requestType === RequestType.Group;
    const groupTask = isGroupTask ? task : undefined;
    const hasAppointments = !!task.appointments.length;
    const hasPublishedInvoices = groupTask?.requestTypeDetails?.hasPublishedInvoices;

    return (
      <div className="relative">
        <MaintenanceCancelModal
          isRenter={isRenter}
          show={cancelModal}
          isCancelRecurrence={isRecurring}
          close={handleCloseModal}
          taskId={task.uniqueId}
        />

        {!cancelModal && (
          <>
            <div className="mb-2xl">
              <ModalMaintenanceTitleModalSubtitleandLabels
                title={task.summary}
                streetAddress={getFormattedAddress(task.address)}
                jobId={task.jobId}
                labels={getAllLabels}
              />
            </div>
            {isGroupTask && (
              <>
                {((shouldRequestAccountResponsibility && task.requestCategory !== 'InHomeRepairs') ||
                  (hasPendingQuoteApproval && task.requestCategory === 'InHomeRepairs')) && (
                  <MaintenanceAccountResponsibilityBanner componentRef={accountResponsibilityRef} />
                )}

                <GroupEmployeeAssignments
                  task={task}
                  report={improvementsReport}
                  listingsData={listings}
                  propertiesData={propertiesData}
                  inspectionData={inspection}
                />
                {task.media && task.media.length > 0 && (
                  <div className="mt-sm">
                    <MediaGallery<MaintenanceMediaModel> media={task.media} />
                  </div>
                )}
                <Space />
                {task.requestCategory !== 'InHomeRepairs' && (
                  <>
                    <GroupImprovementCards
                      report={improvementsReport}
                      groupTask={groupTask}
                      hasAppointments={hasAppointments}
                      isRenter={isRenter}
                      listingsData={listings}
                    />
                    <Space value={SPACE_TYPES.LG} />
                  </>
                )}
                {task.requestCategory === 'InHomeRepairs' && homecareSpecialist && (
                  <>
                    <MaintenanceHomecareSpecialist
                      homecareSpecialist={homecareSpecialist}
                      onScheduleACallPress={() => scheduleHomecareSpecialistCall()}
                    />
                    <Space value={SPACE_TYPES.LG} />
                  </>
                )}
                {hasAppointments && (
                  <>
                    <MaintenanceScheduleComponent
                      appointments={task.appointments}
                      maintenance={task}
                      showMaintenanceInfo={task.requestCategory === 'InHomeRepairs'}
                    />
                    <Space value={SPACE_TYPES.XL} />
                  </>
                )}
                {task.requestCategory !== 'InHomeRepairs' && isRenter && (
                  <GroupResidentReceiptCosts groupTask={groupTask} />
                )}
                {task.requestCategory !== 'InHomeRepairs' && !isRenter && (
                  <GroupHomeownerReceiptCosts groupTask={groupTask} report={improvementsReport} />
                )}

                {hasPublishedInvoices && <MaintenanceBalance task={task} isRenter={isRenter} />}

                {task.requestCategory === 'InHomeRepairs' && (
                  <Proposals
                    maintenance={task}
                    userRole={isRenter ? 'Resident' : 'Homeowner'}
                    fetchTask={fetchTask}
                    accountResponsibilityRef={accountResponsibilityRef}
                    onScheduleHomecareSpecialistCall={() => scheduleHomecareSpecialistCall()}
                  />
                )}
              </>
            )}

            {!isGroupTask && (
              <div className="relative">
                {shouldRequestAccountResponsibility && (
                  <MaintenanceAccountResponsibilityBanner componentRef={accountResponsibilityRef} />
                )}

                <ModalMaintenanceSummary task={task} hideDetails={isGroupTask} collapsed={false} />

                {homecareSpecialist && (
                  <>
                    <Space value={SPACE_TYPES.MD} />
                    <MaintenanceHomecareSpecialist
                      homecareSpecialist={homecareSpecialist}
                      onScheduleACallPress={() => scheduleHomecareSpecialistCall()}
                    />
                  </>
                )}

                {hasAppointments && (
                  <>
                    <Space value={SPACE_TYPES.XL} />
                    <MaintenanceScheduleComponent
                      appointments={task.appointments}
                      maintenance={task}
                      showMaintenanceInfo={false}
                    />
                  </>
                )}
                {(task.actualCost || task.estimatedCost) && (
                  <div className="mt-xl">
                    <Collapsible
                      title={
                        task.actualCost ? maintenanceCostStrings.titleReceipt : maintenanceCostStrings.titleEstimated
                      }
                      inner={
                        <>
                          <MaintenanceCost
                            task={task}
                            userRole={isRenter ? 'Resident' : 'Homeowner'}
                            onConsentChange={fetchTask}
                            accountResponsibilityRef={accountResponsibilityRef}
                            showScheduleCall={!!homecareSpecialist?.employee?.calendarLink}
                            onScheduleHomecareSpecialistCall={() => scheduleHomecareSpecialistCall()}
                            showItemPrices={task.requestCategory !== 'InHomeRepairs'}
                            roundedTop
                          />
                          {!task.isLegacyInvoicing && !isRenter && (
                            <div className="mt-sm">
                              <SplitInfoBox
                                financePlanId={financePlanId}
                                hasOpenBalance={hasEligibleOpenBalance}
                                isMaintenanceCompleted={task.result === 'Completed'}
                              />
                            </div>
                          )}
                        </>
                      }
                      collapsed={false}
                    />
                  </div>
                )}
                {renderMaintenanceApprovalAndResponsibilityComponent()}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <div className="w-full flex flex-col items-center">
      <div className="flex py-xs px-xs justify-end sm:py-xs sm:px-sm w-full">
        <ButtonBase className="p-xs sm:p-sm" onClick={onClosePress}>
          <IconClose height={16} />
        </ButtonBase>
      </div>
      {isSpinnerVisible ? (
        <div className={clsx('m-auto w-screen', styles.spinner)}>
          <Spinner />
        </div>
      ) : (
        <div className={cx('wrapper')}>
          <Grid>
            {task?.uniqueId && (
              <div className={cx('maintenanceStatusModal', { hideUndefinedText: !task?.uniqueId })}>
                {renderForEveryTask()}
                {renderBottom()}
              </div>
            )}
          </Grid>
        </div>
      )}
    </div>
  );
}

export default MaintenanceStatus;
