import { capitalize } from './capitalize.js';
import { words } from './words.js';

/**
 * Converts [camel case](https://en.wikipedia.org/wiki/Camel_case) `string` to
 * [kebab case](https://en.wikipedia.org/wiki/Letter_case#Special_case_styles).
 *
 * @param {string} [string=''] The string to convert.
 * @returns {string} Returns the kebab cased string.
 * @see camelCase, lowerCase, snakeCase, startCase, upperCase, upperFirst
 * @example
 *
 * camelCase('fooBar')
 * // => 'foo-bar'
 *
 * camelCase('FooBar')
 * // => 'foo-bar'
 *
 * camelCase('AFooBar')
 * // => 'a-foo-bar'
 */
function camelCase(string) {
  if (string === void 0) {
    string = '';
  }
  return words(string).map(function (word, index) {
    return index === 0 ? word.toLowerCase() : capitalize(word.toLowerCase());
  }).join('');
}

export { camelCase };
