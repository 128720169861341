import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Spinner, Text } from '@belong/ui';
import { useProperties } from 'accounts/hooks/homeowners/use-properties';
import { useActiveFullManagementHomes } from 'hooks/useActiveFullManagementHomes';
import { startCase, toLower } from 'lodash-es';
import { HomeServiceForm } from 'maintenance/components/home-service-form/home-service-form';
import { NavBar } from 'maintenance/components/nav-bar/nav-bar';
import { RecurringProServiceForm } from 'maintenance/components/recurring-pro-service-form/recurring-pro-service-form';
import { STRINGS } from 'maintenance/strings';
import type { EditProServiceRouteParams, RecurringProServicePreset } from 'maintenance/types';
import { upgradesNames } from 'maintenance/utils';
import RenterAccountLease from 'models/renterAccounts/RenterAccountLease';
import { fetchRecurringProServicePresets } from 'store/redux/pros/actions';
import { selectRecurringProServicePresets } from 'store/redux/pros/selectors';
import { fetchResidentLeases } from 'store/redux/renter-accounts/actions';
import { selectLeases } from 'store/redux/renter-accounts/selectors';
import { getLeaseStatus, LEASE_STATUS } from 'utils/formateDate/getTimeForLeasingStatus';

export function CreateProService() {
  const [isLoading, setIsLoading] = useState(false);
  const { location } = useHistory();
  const dispatch = useDispatch();
  const leases = useSelector<unknown, RenterAccountLease[]>(selectLeases);
  const { proService, taskId } = useParams<EditProServiceRouteParams>();
  const recurringProServicePresets = useSelector(selectRecurringProServicePresets) as RecurringProServicePreset[];
  const activeFullManagementHomes = useActiveFullManagementHomes();

  const isHomeOwnerView = location.pathname.includes('homeowner');

  const { properties = [] } = useProperties(isHomeOwnerView);

  const currentPreset = recurringProServicePresets.find(
    (preset) => preset.recurringTask.toLowerCase() === proService.toLowerCase()
  );

  const hasClaimData = isHomeOwnerView ? !properties.length : !leases.length;

  const currentLease = leases?.filter((lease) => getLeaseStatus(lease) === LEASE_STATUS.CURRENT)?.[0];

  function getTitle() {
    if (currentPreset) return currentPreset?.summary;
    if (isHomeOwnerView) {
      return taskId ? STRINGS['edit-request'] : STRINGS['create-request'];
    }
    const task = toLower(proService);
    if (Object.keys(upgradesNames).includes(task)) return 'Upgrade Something';
    if (task === 'repair') return 'Repair';
    if (task === 'keysandaccess') return 'Keys Please!';
    if (task === 'haulingandstorage') return 'Haul Something';
    else {
      return startCase(`${proService} Something`);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await dispatch(fetchRecurringProServicePresets());

        if (!isHomeOwnerView) {
          await dispatch(fetchResidentLeases());
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, isHomeOwnerView]);

  if (hasClaimData || isLoading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <main className="w-full flex flex-col">
      <NavBar isHomeOwnerView={isHomeOwnerView} />
      <div className="px-2sm sm:px-0 sm:max-w-[730px] self-center w-full">
        <header className="mb-2xl mt-2sm text-center">
          <Text as="h1" fontWeight="semibold" variant="h2">
            {getTitle()}
          </Text>
        </header>
        {currentPreset ? (
          <RecurringProServiceForm
            isHomeOwnerView={isHomeOwnerView}
            preset={currentPreset}
            properties={activeFullManagementHomes}
            unit={currentLease?.unitInfo}
          />
        ) : (
          <HomeServiceForm
            isHomeOwnerView={isHomeOwnerView}
            properties={activeFullManagementHomes}
            unit={currentLease?.unitInfo}
            proService={proService}
          />
        )}
      </div>
    </main>
  );
}
