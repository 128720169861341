import React, { useState } from 'react';
import { IconCaretUp } from '@belong/icons';
import { Button } from '@belong/ui';
import classNames from 'classnames/bind';
import clsx from 'clsx';
import CollapsibleTitle, { RightIcon } from 'components/Collapsible/CollapsibleTitle/CollapsibleTitle';
import styles from './Collapsible.module.css';

const cx = classNames.bind(styles);

interface CollapsibleProps {
  title: string;
  inner: React.ReactNode;
  showWhenCollapsed?: React.ReactNode;
  titleComponent?: React.ReactNode;
  collapsed?: boolean;
  createMarginAndPaddingClassName?: string;
  disabled?: boolean;
  defaultClassName?: string;
  className?: string;
}

const Collapsible: React.FC<CollapsibleProps> & { RightIcon: typeof RightIcon } = ({
  title,
  inner,
  showWhenCollapsed = '',
  titleComponent,
  collapsed: initialCollapsed = true,
  createMarginAndPaddingClassName = '',
  disabled = false,
  defaultClassName = 'contents',
  className,
  ...rest
}) => {
  const [collapsed, setCollapsed] = useState(initialCollapsed);

  const handleCollapse = () => {
    if (!disabled) {
      setCollapsed((prevCollapsed) => !prevCollapsed);
    }
  };

  const isOpen = !collapsed;

  return (
    <div className={clsx(defaultClassName, isOpen ? 'mb-2xl' : 'mb-lg')}>
      <section className={clsx('collapsible', className)} {...rest}>
        {titleComponent ? (
          <div className="w-full" onClick={handleCollapse}>
            <div className="flex justify-between items-start w-full">
              {titleComponent}
              <Button variant="text">
                {isOpen ? <IconCaretUp width={13} /> : <IconCaretUp className="rotate-180" width={13} />}
              </Button>
            </div>
          </div>
        ) : (
          <div className="w-full">
            <CollapsibleTitle titleText={title} handleClick={handleCollapse} isOpen={isOpen} />
          </div>
        )}
      </section>
      <section className={cx({ collapsed }, createMarginAndPaddingClassName)}>{inner}</section>
      {collapsed && showWhenCollapsed}
    </div>
  );
};

Collapsible.RightIcon = RightIcon;

export default Collapsible;
