import React from 'react';
import classNames from 'classnames/bind';
import Icon, { ICONS } from 'components/Icon/Icon';
import Image from 'components/Image/Image';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import ProfileImage from 'components/ProfileImage/ProfileImage';
import ProfileImageWithLabel, { MODES } from 'components/ProfileImageWithLabel/ProfileImageWithLabel';
import { startCase } from 'lodash-es';
import PropTypes from 'prop-types';
import styles from './BelongEmployeeProfileModal.module.css';

const cx = classNames.bind(styles);

const TopSection = ({ employee }) => {
  if (employee?.bannerImage || employee?.bannerImageUrl)
    return (
      <>
        <div className={cx('banner-image-wrapper')}>
          <Image src={employee?.bannerImage || employee?.bannerImageUrl} className={cx('banner-image')} alt="" />
        </div>
        <div className={cx('profile-label-wrapper')}>
          <div className={cx('profile-image-wrapper')}>
            <ProfileImage profileImage={employee?.profileImage || employee?.profileImageUrl} scale={1} makeRound />
          </div>
          <div className={cx('label-wrapper')}>
            <div className={cx('name-wrapper')}>{employee?.firstName}</div>
            {/* TODO: One days i will be forgiven for this, literally last minute change before relase,
          doing a hack to send years of experience inside an array of divs,
          cannot put anywhere else without testing everything. Will fix after release. By Cesar. */}
            <div className={cx('job-title-wrapper')}>
              {Array.isArray(employee?.jobTitle) ? employee?.jobTitle[0] : employee?.jobTitle}
            </div>
          </div>
        </div>
      </>
    );

  return (
    <div className={cx('without-banner-profile-label-wrapper')}>
      <ProfileImageWithLabel
        profileImage={employee?.profileImage || employee?.profileImageUrl}
        title={`${employee?.firstName} ${employee?.lastName}`}
        subtitle={employee?.jobTitle}
        scale={1}
        mode={MODES.LABEL_RIGHT}
      />
    </div>
  );
};
TopSection.propTypes = {
  employee: PropTypes.object,
};

const IconWithLabel = ({ icon, title, subtitle, className }) => {
  return (
    <div className={cx('icon-label-wrapper', className)}>
      <div className={cx('icon-container')}>
        <Icon icon={icon} scale={0.53} />
      </div>
      <div className={cx('label-wrapper')}>
        <div className={cx('title')}>{title}</div>
        <div className={cx('subtitle')}>{subtitle}</div>
      </div>
    </div>
  );
};
IconWithLabel.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
};

const BelongEmployeeProfileModal = ({ employee, onHide, show }) => {
  const areaOrRegion = employee?.serviceArea || employee?.coverageRegion;
  const specialities = employee?.specialities?.slice(0, 3).map(startCase).join(', ');
  const plusSpecialities = employee?.specialities?.length - 2;

  return (
    <ModalV2 closeButton onHide={onHide} show={show}>
      <TopSection employee={employee} />
      <div className={cx('profile-details-section')}>
        <div className={cx('icons-section-wrapper')}>
          {areaOrRegion && <IconWithLabel icon={ICONS.MAP.DEFAULT} title="Coverage" subtitle={areaOrRegion} />}

          {employee?.homeTown && (
            <IconWithLabel icon={ICONS.BABY_WRAPPED.DEFAULT} title="Grew up in" subtitle={employee?.homeTown} />
          )}

          {employee?.specialities && employee?.specialities?.join && (
            <IconWithLabel
              className={cx('full-width')}
              icon={ICONS.REPAIR.DEFAULT}
              title="Specializes in"
              subtitle={`${specialities}${plusSpecialities > 0 ? ` + ${plusSpecialities}` : ''}`}
            />
          )}
        </div>

        {!!employee?.quote && (
          <div className={cx('text-section')}>
            <div className={cx('title')}>Favorite Quote</div>
            {employee?.quote}
          </div>
        )}

        {!!employee?.aboutMe && (
          <div className={cx('text-section')}>
            <div className={cx('title')}>About Me</div>
            {employee?.aboutMe}
          </div>
        )}
      </div>
    </ModalV2>
  );
};

BelongEmployeeProfileModal.propTypes = {
  employee: PropTypes.shape({
    college: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    aboutMe: PropTypes.string,
    quote: PropTypes.string,
    specialities: PropTypes.string,
    homeTown: PropTypes.string,
    serviceArea: PropTypes.string,
    profileImage: PropTypes.string,
    bannerImage: PropTypes.string,
    jobTitle: PropTypes.node,
    coverageRegion: PropTypes.string,
  }).isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default BelongEmployeeProfileModal;
