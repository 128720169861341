import { MaintenanceModel, MaintenancePaymentModel, MaintenancePriceRangeAggregateModel } from 'api/models';
import classNames from 'classnames/bind';
import { ICONS } from 'components/Icon/Icon.js';
import { LabelItem } from 'components/Label/labelTypes';
import { MAINTENANCE_TASK_MOBILE_PROPS } from 'containercomponents/MaintenanceTaskListContainerComponent/MaintenanceTasks/MaintenanceProps';
import { Grid, Row, Col } from 'forkedlibraries/react-bootstrap';
import MaintenanceTaskCost from './MaintenanceTaskCost/MaintenanceTaskCost';
import MaintenanceTaskIconLabel from './MaintenanceTaskIconLabel/MaintenanceTaskIconLabel';
import MaintenanceTaskLabelandDate from './MaintenanceTaskLabels/MaintenanceTaskLabels';
import MaintenanceTaskCostandStatus from './MaintenanceTaskStatus/MaintenanceTaskStatus';
import styles from './MaintenanceTasks.module.css';

const cx = classNames.bind(styles);

type MaintenanceTasksProps = {
  maintenanceLabels: LabelItem[];
  maintenanceDate?: string | number;
  maintenanceTaskImage?: string;
  maintenanceTask?: string;
  maintenanceTaskStatusText: string;
  estimatedCost: '' | 'Pricing TBD' | MaintenancePaymentModel['estimatedAdjustmentApplied'];
  actualCost: '' | 'Free' | MaintenancePaymentModel['actualAdjustmentApplied'];
  maintenanceTaskStatus: string;
  maintenanceTaskStatusLabel: string;
  isConsentPendingList?: boolean;
  priceRange?: MaintenancePriceRangeAggregateModel;
  numberOfOptions?: number;
  requestCategory?: MaintenanceModel['requestCategory'];
  requestType?: MaintenanceModel['requestType'];
};

const MaintenanceTasks = ({
  maintenanceLabels,
  maintenanceDate = '',
  maintenanceTaskImage = ICONS.COMMUNICATION.DEFAULT,
  maintenanceTask = 'Please Enter A Task',
  maintenanceTaskStatusText,
  estimatedCost,
  actualCost,
  priceRange,
  numberOfOptions,
  maintenanceTaskStatus,
  maintenanceTaskStatusLabel,
  isConsentPendingList = false,
  requestCategory,
  requestType,
}: MaintenanceTasksProps) => {
  return (
    <div
      className={cx(
        'maintenance-tasks-list-flex',
        'transition-colors duration-hover hover:border-navy hover:bg-light-gray',
        isConsentPendingList && 'border-red bg-red-translucent-light'
      )}
    >
      <Grid className="px-sm py-sm">
        <Row className={cx('max-width', 'mx-0')}>
          <div className={cx('flexItemsForMaintenance')}>
            <Col md={6} className={cx('noRightPadding', 'pl-0')}>
              <MaintenanceTaskIconLabel
                maintenanceTaskImage={maintenanceTaskImage}
                maintenanceTask={maintenanceTask}
                maintenanceDate={maintenanceDate}
                maintenanceTaskStatusLabel={maintenanceTaskStatusLabel}
                maintenanceTaskStatusText={maintenanceTaskStatusText}
              />
            </Col>
            <Col md={2} className={cx('noRightPadding', 'pl-0')}>
              <MaintenanceTaskLabelandDate maintenanceLabels={maintenanceLabels} />
            </Col>
            <Col md={4} className={cx('noRightPadding')}>
              <MaintenanceTaskCostandStatus
                maintenanceTaskStatusLabel={maintenanceTaskStatusLabel}
                moreInfo={maintenanceTaskStatusText}
                mobileDisplay={MAINTENANCE_TASK_MOBILE_PROPS.HAS_MOBILE}
              />
            </Col>
          </div>
        </Row>
        <Row className={cx('max-width', 'mx-0', 'maintenanceEstimatedPriceContent')}>
          <div className={cx('flexItemsForMaintenance')}>
            <Col md={6} className={cx('noRightPadding', 'pl-0')}>
              <MaintenanceTaskCost
                maintenanceTaskStatus={maintenanceTaskStatus}
                estimatedCost={estimatedCost}
                actualCost={actualCost}
                priceRange={priceRange}
                numberOfOptions={numberOfOptions}
                requestCategory={requestCategory}
                requestType={requestType}
                mobileDisplay={MAINTENANCE_TASK_MOBILE_PROPS.HAS_MOBILE}
              />
            </Col>
          </div>
        </Row>
      </Grid>
    </div>
  );
};

export default MaintenanceTasks;
