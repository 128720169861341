import { PAYMENT_STEPS, PAYMENT_STEP_KEYS } from 'post-inspection/constants/improvements-report';

type Params = {
  isLegacyInvoicing?: boolean;
  isPreMoveOut?: boolean;
};

type Result = {
  steps: typeof PAYMENT_STEPS;
};

export const useImprovementReportPaymentSteps = ({
  isLegacyInvoicing = false,
  isPreMoveOut = false,
}: Params): Result => {
  if (isLegacyInvoicing || isPreMoveOut) {
    return {
      steps: PAYMENT_STEPS.filter((paymentStep) => paymentStep.key === PAYMENT_STEP_KEYS.Summary),
    };
  }

  return {
    steps: PAYMENT_STEPS,
  };
};
