import classNames from 'classnames/bind';
import Label from 'components/Label/Label';
import { LabelItem } from 'components/Label/labelTypes';
import styles from './MaintenanceTaskLabelandDate.module.css';

const cx = classNames.bind(styles);

interface MaintenanceTaskLabelandDateProps {
  maintenanceLabels: LabelItem[];
}

const MaintenanceTaskLabelandDate = ({ maintenanceLabels }: MaintenanceTaskLabelandDateProps) => {
  return (
    <div className={cx('maintenanceTaskLabelandDate', 'pr-0 lg:h-2xl lg:flex lg:items-center lg:p-0')}>
      {maintenanceLabels.map((label, index) => (
        <Label text={label.text} color={label.color} key={index} />
      ))}
    </div>
  );
};

export default MaintenanceTaskLabelandDate;
