import React from 'react';
import classNames from 'classnames/bind';
import Label from 'components/Label/Label';
import PropTypes from 'prop-types';
import styles from './LabeledList.module.css';

const cx = classNames.bind(styles);

const LabeledList = ({ title, tooltip, items }) => (
  <div>
    <div className={cx('title-container')}>
      <div className={cx('title')}>{title}</div>
      {tooltip}
    </div>
    {items.map((item) => (
      <div key={item.text} className={cx('item-container')}>
        <div className={cx('item-text')}>{item.text}</div>
        <Label text={item.labelText} color={item.labelColor} />
      </div>
    ))}
  </div>
);

LabeledList.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.node,
  items: PropTypes.array.isRequired,
};

LabeledList.defaultProps = {
  tooltip: null,
};

export default LabeledList;
