import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Confetti } from 'animations/Confetti/Confetti';
import classNames from 'classnames/bind';
import Button, { BUTTON_SIZES } from 'components/Button/Button';
import FullPageSpinner from 'components/FullPageSpinner/FullPageSpinner';
import Image from 'components/Image/Image';
import { ShareableBanner } from 'components/Referral/ShareableBanner';
import TextBubbleWithUserProfile from 'components/TextBubbleWithUserProfile/TextBubbleWithUserProfile';
import AccountHeaderContainer from 'containers/AccountHeaderContainer/AccountHeaderContainer';
import { ContactUs } from 'containers/ContactUs/ContactUs';
import ResidentListContainer from 'containers/ResidentListContainer/ResidentListContainer';
import { Box, Flex, Text, Timeline } from 'design-system';
import { useModal } from 'hooks/useModal';
import Page from 'layouts/Page/Page';
import { timelineSteps } from 'lease-signing-flow/constants/success';
import { getMostRecentLease } from 'lease-signing-flow/utils/lease';
import { UserSignatureStatus } from 'models/enums';
import PropTypes from 'prop-types';
import { BASE_PATHS } from 'routes/paths';
import { fetchResidentLeases } from 'store/redux/renter-accounts/actions';
import { selectBookmarks } from 'store/redux/renter-accounts/selectors';
import { LEASE_SIGNING_FLOW_STRINGS as STRINGS } from 'strings/lease-signing-flow.strings';
import styles from './old-success.module.css';

const cx = classNames.bind(styles);

const HeaderLinks = [
  <ContactUs key="ContactUs" disableChat />,
  <AccountHeaderContainer key="AccountHeaderContainer" />,
];

const getBubbleText = (lease, application) => {
  const missingInterviews = application?.applicationInterview?.status !== 'Passed';
  const hasPendingSignatures = lease?.residents?.reduce(
    (acc, r) => acc || r?.leaseInfo?.signatureStatus === UserSignatureStatus.Pending,
    false
  );

  if (lease?.leaseInfo?.basicInfo?.renewalNumber > 0) return STRINGS['success.employee.title-renewal'];

  if (missingInterviews && hasPendingSignatures)
    return STRINGS['success.employee.title.self-serve-signatures-pending-interviews-pending'];

  if (missingInterviews) return STRINGS['success.employee.title.self-serve-interview-pending'];

  if (hasPendingSignatures) return STRINGS['success.employee.title.self-serve-signatures-pending'];

  return STRINGS['success.employee.title'];
};

const Success = ({ home, lease, bookmarks, employee, homeId }) => {
  const dispatch = useDispatch();
  const [loading, , stopLoader] = useModal(true);
  const [leaseInfo, setLeaseInfo] = useState();
  const isRenewal = lease?.leaseInfo?.basicInfo?.renewalNumber > 0;

  useEffect(() => {
    setTimeout(() => {
      stopLoader();
    }, 1000);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const accountLeases = await dispatch(fetchResidentLeases({ includeAllResidents: true }));
      const mostRecentLeaseForHomeWithExecuted = getMostRecentLease(accountLeases, homeId, true);
      setLeaseInfo(mostRecentLeaseForHomeWithExecuted);
    }
    if (homeId) {
      fetchData();
    }
  }, [homeId, lease]);

  const currentApplication = bookmarks?.find(
    (bookmark) => bookmark.applicationId && bookmark.unit.basicInfo.unitId === home.basicInfo.unitId
  );

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <Page
      headerMainProps={{
        fixed: true,
        navigationComponents: HeaderLinks,
      }}
      footer={false}
    >
      <>
        <Confetti />

        <div className={cx('success-page')}>
          <div className={cx('content')}>
            <Box pb="2xl">
              <TextBubbleWithUserProfile employee={employee} text={getBubbleText(lease, currentApplication, home)} />
            </Box>

            <Flex flex={1} justifyContent="flex-end" pb="2xl">
              <ResidentListContainer withStatus residents={leaseInfo?.leaseInfo} />
            </Flex>

            <Flex justifyContent="center" pb="2xl">
              <Button
                to={{ pathname: BASE_PATHS.ACCOUNTS }}
                label="Go to Account"
                className={cx('button')}
                size={BUTTON_SIZES.FLUID_LONG_RESPONSIVE}
              />
            </Flex>

            {!isRenewal && (
              <Flex className="items-center" flexDirection="column" mb="2xl">
                <Flex justifyContent="center" mb="sm">
                  <Text fontSize="h3" fontWeight="semibold">
                    {STRINGS['success.timeline.title']}
                  </Text>
                </Flex>
                <Timeline
                  items={timelineSteps}
                  alternate
                  checkedNode={
                    <div style={{ height: 8, width: 8 }} className="bg-green border-2 border-navy rounded" />
                  }
                  showUncheckedAsDisabled
                  uncheckedNode={
                    <div
                      style={{ height: 14, width: 14 }}
                      className="absolute border-solid border-2 border-dark-gray rounded"
                    />
                  }
                />
              </Flex>
            )}

            <div className="flex w-full mb-5xl">
              {!isRenewal && (
                <ShareableBanner
                  body={STRINGS.shareableInfo.bannerMessage}
                  shareableMessage={STRINGS.shareableInfo.shareableMessage}
                />
              )}
              {isRenewal && (
                <ShareableBanner
                  title={STRINGS.shareableRenewal.bannerTitle}
                  body={STRINGS.shareableRenewal.bannerMessage}
                  shareableMessage={STRINGS.shareableRenewal.shareableMessage}
                />
              )}
            </div>
          </div>
        </div>

        <div className={cx('background-container-desktop')}>
          <Image src="/city_images/success_page_city_bg.svg" alt="" />
        </div>
        <div className={cx('background-container-mobile')}>
          <Image src="/city_images/success_page_city_bg_mobile.svg" alt="" />
        </div>
      </>
    </Page>
  );
};

Success.propTypes = {
  lease: PropTypes.object.isRequired,
  employee: PropTypes.object.isRequired,
  home: PropTypes.object.isRequired,
  bookmarks: PropTypes.array.isRequired,
  homeId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  bookmarks: selectBookmarks(state),
});

export default connect(mapStateToProps)(Success);
