import { MaintenanceModel, MaintenancePaymentModel, MaintenanceRecurrenceModel } from 'api/models';
import classNames from 'classnames/bind';
import BDate, { DATE_TYPES } from 'components/BDate/BDate';
import MaintenanceTasks from 'containercomponents/MaintenanceTaskListContainerComponent/MaintenanceTasks/MaintenanceTasks';
import ButtonBase from 'corecomponents/ButtonBase/ButtonBase';
import { shouldRequestResponsibilityConsent } from 'maintenance/utils';
import { RequestType } from 'models/enums';
import { formatMaintenanceStringsForTasks, getImage, getStringFromTime } from 'utils/formatMaintenance';
import { LABEL_COLOR_TYPES, LabelItem } from '../Label/labelTypes';
import styles from './MaintenanceTaskComponent.module.css';

const cx = classNames.bind(styles);

interface MaintenanceTaskComponentProps {
  showMaintenanceModal: (task: MaintenanceModel | MaintenanceRecurrenceModel) => () => void;
  tasks: MaintenanceModel[];
  isConsentPendingList?: boolean;
  isRenter: boolean;
}

const MaintenanceTaskComponent = ({
  showMaintenanceModal,
  tasks = [],
  isConsentPendingList = false,
  isRenter,
}: MaintenanceTaskComponentProps) => {
  const renderStatusText = (task: MaintenanceModel | MaintenanceRecurrenceModel) => {
    if ('status' in task && task.status === 'Closed') {
      return <BDate time={task.closedOn} formatType={DATE_TYPES.MAINTENANCE_DEFAULT_DATE_TIME_TIME_ZONE} />;
    }

    if ('status' in task && task.status === 'InProgress' && task.nextAppointmentUniqueId) {
      const nextAppointment = task.appointments.find((t) => t.uniqueId === task.nextAppointmentUniqueId);

      if (nextAppointment?.isAllDay) {
        return (
          <span className={cx('all-day-wrapper')}>
            <BDate time={nextAppointment.scheduledStartOn} formatType={DATE_TYPES.MAINTENANCE_DEFAULT_DATE} /> (All Day)
          </span>
        );
      }

      return (
        <BDate
          time={nextAppointment.scheduledStartOn}
          formatType={DATE_TYPES.MAINTENANCE_DEFAULT_DATE_TIME_TIME_ZONE}
        />
      );
    }
  };

  return (
    <>
      {tasks.map((task) => {
        const hasMissingConsent = shouldRequestResponsibilityConsent(task, isRenter);
        const getTextToDisplay = hasMissingConsent ? 'PendingConsent' : formatMaintenanceStringsForTasks(task);

        let image = getImage(task.requestType);

        let displayEstimatedResponsibleCost: '' | 'Pricing TBD' | MaintenancePaymentModel['estimatedAdjustmentApplied'];
        let displayResponsibleCost: '' | 'Free' | MaintenancePaymentModel['actualAdjustmentApplied'];

        if ('involvedRole' in task && task.involvedRole === 'Resident') {
          const renterResponsibility = task.payments.find((payment) => payment.paidBy === task.involvedRole);

          if (renterResponsibility?.percentage > 0) {
            displayEstimatedResponsibleCost = renterResponsibility?.estimatedAdjustmentApplied || 'Pricing TBD';
            displayResponsibleCost = renterResponsibility.actualAdjustmentApplied;
          } else if (task.actualCost?.totalCost) {
            displayResponsibleCost = 'Free';
          } else {
            displayEstimatedResponsibleCost = '';
            displayResponsibleCost = '';
          }
        }

        if ('involvedRole' in task && task.involvedRole === 'Homeowner') {
          const homeOwnerResponsibility = task.payments.find((payment) => payment.paidBy === task.involvedRole);
          if (task.requestType === RequestType.Group) {
            displayEstimatedResponsibleCost = homeOwnerResponsibility?.estimatedNetTotal || 'Pricing TBD';
            if (task.requestTypeDetails?.hasPublishedInvoices) {
              displayResponsibleCost = homeOwnerResponsibility?.actualNetTotal;
            }
          } else {
            if (homeOwnerResponsibility?.percentage > 0) {
              displayEstimatedResponsibleCost = homeOwnerResponsibility?.estimatedAdjustmentApplied || 'Pricing TBD';
              displayResponsibleCost = homeOwnerResponsibility.actualAdjustmentApplied;
            } else if (task.actualCost?.totalCost) {
              displayResponsibleCost = 'Free';
            } else {
              displayEstimatedResponsibleCost = '';
              displayResponsibleCost = '';
            }
          }
        }

        const labels: LabelItem[] = [];

        if ('urgency' in task && task.urgency === 'Urgent') {
          labels.push({
            text: 'Urgent',
            color: LABEL_COLOR_TYPES.RED,
          });
        }

        if ('recurrence' in task && task.recurrence?.repeatFrequencyDays) {
          image = getImage('Recurring');
          labels.push({
            text: getStringFromTime(task.recurrence.repeatFrequencyDays),
          });
        }

        return (
          <ButtonBase onClick={showMaintenanceModal(task)} className="w-full mb-xs last-of-type:mb-0" key={task.id}>
            <MaintenanceTasks
              maintenanceLabels={labels}
              maintenanceDate={task.inspection?.completedOn || task.createdOn}
              maintenanceTask={task.summary}
              maintenanceTaskStatusLabel={getTextToDisplay}
              maintenanceTaskStatusText={renderStatusText(task)}
              maintenanceTaskImage={image}
              estimatedCost={displayEstimatedResponsibleCost}
              actualCost={displayResponsibleCost}
              priceRange={'priceRange' in task ? task.priceRange : undefined}
              numberOfOptions={'numberOfOptions' in task ? task.numberOfOptions : undefined}
              requestCategory={'requestCategory' in task ? task.requestCategory : undefined}
              requestType={'requestType' in task ? task.requestType : undefined}
              maintenanceTaskStatus={'status' in task ? task?.status : undefined}
              isConsentPendingList={isConsentPendingList}
            />
          </ButtonBase>
        );
      })}
    </>
  );
};

export default MaintenanceTaskComponent;
