import { PaymentMethodSplitStep } from 'post-inspection/components/payment-method-split-step/payment-method-split-step';
import { PaymentMethodStep } from 'post-inspection/components/payment-method-step/payment-method-step';
import { PaymentSummaryStep } from 'post-inspection/components/payment-summary-step/payment-summary-step';
import type { PaymentStep, PaymentStepKey } from 'post-inspection/models/improvements-report';

export const PAYMENT_STEP_KEYS: Record<PaymentStepKey, PaymentStepKey> = {
  PaymentMethod: 'PaymentMethod',
  PaymentMethodDetails: 'PaymentMethodDetails',
  NewPaymentMethod: 'NewPaymentMethod',
  PaymentMethodSplit: 'PaymentMethodSplit',
  Success: 'Success',
  Summary: 'Summary',
};

export const PAYMENT_STEPS: PaymentStep[] = [
  {
    Component: PaymentSummaryStep,
    key: PAYMENT_STEP_KEYS.Summary,
  },
  {
    Component: PaymentMethodStep,
    key: PAYMENT_STEP_KEYS.PaymentMethod,
  },
  {
    Component: PaymentMethodSplitStep,
    key: PAYMENT_STEP_KEYS.PaymentMethodSplit,
  },
];
