import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Label from 'components/Label/Label';
import { labelShape } from 'components/Label/labelTypes';
import styles from 'containercomponents/MaintenanceStatus/ModalMaintenanceTitleModalSubtitleandLabels/ModalMaintenanceTitleModalSubtitleandLabels.module.css';
import Space from 'corecomponents/Space/Space';
import { SPACE_TYPES } from 'corecomponents/Space/spaceTypes';
import { Text } from 'design-system';
import ModalLayout from 'layouts/ModalLayout/ModalLayout';
import PropTypes from 'prop-types';
import { MAINTENACE_STATUS_MODAL_STRINGS } from 'strings/maintenance-status-modal.string';

const cx = classNames.bind(styles);

export default class ModalMaintenanceTitleModalSubtitleandLabels extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    streetAddress: PropTypes.string.isRequired,
    jobId: PropTypes.string.isRequired,
    labels: PropTypes.arrayOf(PropTypes.shape(labelShape)),
  };

  static defaultProps = {
    labels: [],
  };

  render() {
    const { title, streetAddress, jobId, labels } = this.props;
    return (
      <>
        <ModalLayout.Title label={title} className={cx('title', 'mt-0')} />
        <ModalLayout.SubTitle noPadding withMarginTop whiteSpaceNormal>
          <div className={cx('bold')}>
            <Text className="text-navy" fontWeight="semibold">
              {streetAddress}
            </Text>
          </div>
        </ModalLayout.SubTitle>

        {labels.length > 0 && (
          <div className={cx('label-holder')}>
            {labels.map((label, index) => (
              <Label text={label.label} color={label.color} key={index} />
            ))}
          </div>
        )}
        <Space value={SPACE_TYPES.SM} mobileValue={SPACE_TYPES.LG} />
        <div className={cx('center-text')}>
          {`${MAINTENACE_STATUS_MODAL_STRINGS['maintenancemodal.request']} #${jobId}`}
        </div>
      </>
    );
  }
}
