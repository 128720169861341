export function parseOutsideUSExternalProviderData(address) {
  const { address_components } = JSON.parse(address.externalProviderData);

  return address_components.reduce(
    (addressInformation, currentAddressComponent) => {
      const { types } = currentAddressComponent;

      if (types.includes('locality')) {
        return {
          ...addressInformation,
          city: currentAddressComponent.short_name,
        };
      }

      if (types.includes('administrative_area_level_1')) {
        return {
          ...addressInformation,
          state: currentAddressComponent.short_name,
        };
      }

      if (types.includes('postal_code')) {
        return {
          ...addressInformation,
          zipcode: currentAddressComponent.short_name,
        };
      }

      if (types.includes('country')) {
        return {
          ...addressInformation,
          country: currentAddressComponent.short_name,
        };
      }

      return { ...addressInformation };
    },
    {
      city: '',
      state: '',
      zipcode: '',
      country: '',
    }
  );
}
