import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import GeneralIcon, { COLORS } from '../GeneralIcon/GeneralIcon';
import styles from './Label.module.css';
import { LABEL_COLOR_TYPES as labelColors } from './labelTypes';

const cx = classNames.bind(styles);
export const LABEL_COLOR_TYPES = labelColors;

export default class Label extends Component {
  static propTypes = {
    text: PropTypes.string,
    color: PropTypes.oneOf(Object.values(LABEL_COLOR_TYPES)),
    children: PropTypes.node,
    className: PropTypes.string,
    icon: PropTypes.string,
  };

  static defaultProps = {
    color: LABEL_COLOR_TYPES.BLUE,
    children: '',
    text: '',
    className: '',
    icon: '',
  };

  render() {
    const { color, text, children, className, icon } = this.props;

    if (icon) {
      return (
        <span className={cx('label', `${color}`, className, { icon })}>
          <GeneralIcon icon={icon} color={COLORS.WHITE} scale={0.5} />
          <span className={cx('text')}>{children || text}</span>
        </span>
      );
    }

    return <span className={cx('label', `${color}`, className)}>{children || text}</span>;
  }
}
